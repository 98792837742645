import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderCardAttributeActions } from './order-card-attribute.actions';
import { OrderCardAttributeService } from '../../services/order-card-attribute.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderCardAttributeState } from './order-card-attribute.reducer';
import { Store } from '@ngrx/store';
import { OrderCardAttributeResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderCardAttributeEffects extends BaseResourceEffects<OrderCardAttributeResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderCardAttributeService,
  ) {
    super(
      actions$,
      store,
      service,
      orderCardAttributeActions,
      selectOrderCardAttributeState,
    );
  }
}
