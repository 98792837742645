<div class="container" [ngStyle]="props['style']">
  @for (field of field.fieldGroup; track $index) {
    <div class="section">
      <formly-field [field]="field"> </formly-field>
      <button
        color="primary"
        type="button"
        (click)="remove($index)"
        mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
  <button
    id="add-btn"
    color="primary"
    type="button"
    (click)="add()"
    mat-flat-button>
    {{ props['addButtonLabel'] }}
  </button>
</div>
