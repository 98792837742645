import { CustomerProfileResourceTypeMappings } from '@aa/nest/resource';
import { countries, isValidEmail, states } from '@aa/ts/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { customerProfileActions } from '../../state/customer-profile/customer-profile.actions';

// @aa:gen-ignore

export type CustomerProfileFormModel = Partial<
  CustomerProfileResourceTypeMappings['createDTO']
> & {
  email?: string;
  userId?: number;
};
export type CustomerProfileFormModalData =
  FormModalData<CustomerProfileFormModel>;

@Component({
  selector: 'aa-customer-profile-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class CustomerProfileFormModalComponent extends BaseFormModalComponent<
  CustomerProfileFormModel,
  CustomerProfileFormModalData
> {
  title = 'Customer Profile';
  fields: FormlyFieldConfig[] = [
    {
      key: 'userId',
      type: 'input',
      hide: true,
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'First Name',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Last Name',
            type: 'text',
            required: true,
          },
        },
      ],
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email address',
        required: true,
      },
      validators: {
        email: {
          expression: (c: AbstractControl) => !c.value || isValidEmail(c.value),
        },
      },
    },
    {
      key: 'addressLine1',
      type: 'input',
      props: {
        label: 'Address Line 1',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'addressLine2',
      type: 'input',
      props: {
        label: 'Address Line 2',
        type: 'text',
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'city',
          type: 'input',
          props: {
            label: 'City',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'state',
          type: 'select',
          props: {
            label: 'State',
            options: states.map((state) => ({
              label: state.name,
              value: state.code,
            })),
            required: true,
          },
        },
        {
          key: 'zipCode',
          type: 'input',
          props: {
            label: 'Zip Code',
            type: 'text',
            required: true,
          },
        },
      ],
    },
    {
      key: 'country',
      type: 'select',
      defaultValue: 'US',
      props: {
        label: 'Country',
        options: countries.map((country) => ({
          label: country.name,
          value: country.code,
        })),
        required: true,
        disabled: true,
      },
    },
    {
      key: 'stripeCustomerId',
      type: 'input',
      props: {
        label: 'Stripe Customer Id',
        required: false,
      },
    },
    {
      key: 'notes',
      type: 'input',
      props: {
        label: 'Notes',
        type: 'text',
        required: false,
      },
    },
  ];
  override model: CustomerProfileFormModel = {
    country: 'US',
  };

  createAction = customerProfileActions.createItem;
  updateAction = customerProfileActions.updateCustomerProfileWithUserAccount;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: CustomerProfileFormModalData,
    protected override readonly dialogRef: MatDialogRef<CustomerProfileFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
    this.dontStripKeys = ['userId'];
    this.extraUpdateActionData = { andRefresh: true };
  }
}
