import { orderServiceLinkageActions } from './order-service-linkage.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderServiceLinkageResourceTypeMappings } from '@aa/nest/resource';

export type OrderServiceLinkageState =
  BaseResourceState<OrderServiceLinkageResourceTypeMappings>;
export const initialOrderServiceLinkageState: OrderServiceLinkageState =
  initialResourceState;

export const orderServiceLinkageReducer = createBaseResourceReducer<
  OrderServiceLinkageResourceTypeMappings,
  OrderServiceLinkageState
>(initialOrderServiceLinkageState, orderServiceLinkageActions);

export const selectOrderServiceLinkageState = (state: CoreAppState) =>
  state.orderServiceLinkage ?? initialOrderServiceLinkageState;
