import { UserRoleResourceTypeMappings } from '@aa/nest/auth';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { userRoleActions } from './user-role.actions';

// @aa:gen-ignore

export type UserRoleState = BaseResourceState<UserRoleResourceTypeMappings>;
export const initialUserRoleState: UserRoleState = initialResourceState;

export const userRoleReducer = createBaseResourceReducer<
  UserRoleResourceTypeMappings,
  UserRoleState
>(initialUserRoleState, userRoleActions);

export const selectUserRoleState = (state: CoreAppState) =>
  state.userRole ?? initialUserRoleState;
