import { OrderSourceResourceTypeMappings } from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { orderSourceActions } from '../../state/order-source/order-source.actions';

// @aa:gen-ignore

export type OrderSourceFormModel = Partial<
  OrderSourceResourceTypeMappings['createDTO']
>;
export type OrderSourceFormModalData = FormModalData<OrderSourceFormModel>;

@Component({
  selector: 'aa-order-source-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class OrderSourceFormModalComponent extends BaseFormModalComponent<
  OrderSourceFormModel,
  OrderSourceFormModalData
> {
  title = 'Order Source';
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        type: 'text',
        required: true,
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'hexColor',
          type: 'input',
          props: {
            label: 'Hex Color',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'hexColor',
          type: 'input',
          props: {
            label: 'Color Picker',
            type: 'color',
            required: true,
          },
        },
      ],
    },
  ];

  createAction = orderSourceActions.createItem;
  updateAction = orderSourceActions.updateItem;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: OrderSourceFormModalData,
    protected override readonly dialogRef: MatDialogRef<OrderSourceFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
  }
}
