import { isValidEmail } from '@aa/ts/common';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { firstValueFrom, Observable } from 'rxjs';
import { authActions } from '../../state/auth/auth.actions';
import { selectAuth } from '../../state/auth/auth.reducer';
import { CoreAppState } from '../../state/core-app.state';

interface LoginFormModel {
  email: string;
  password: string;
}

@Component({
  selector: 'aa-login-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './login-view.component.html',
  styleUrl: './login-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginViewComponent {
  form = new FormGroup({});
  model: LoginFormModel = {
    email: '',
    password: '',
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email address',
        required: true,
      },
      validators: {
        email: {
          expression: (c: AbstractControl) => !c.value || isValidEmail(c.value),
        },
      },
    },
    {
      key: 'password',
      type: 'input',
      props: {
        keydown: (field: FormlyFieldConfig, e: KeyboardEvent) => {
          if (e.key == 'Enter') {
            e.preventDefault();
            this.submit();
          }
        },
        label: 'Password',
        type: 'password',
        required: true,
      },
    },
  ];

  error$: Observable<string | null | undefined>;
  isStaffApp$: Observable<boolean>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly store: Store<CoreAppState>,
    private readonly router: Router,
  ) {
    this.store.dispatch(authActions.clearError());
    if (isPlatformBrowser(this.platformId)) {
      this.store
        .select((s) => selectAuth(s).user)
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          if (user) {
            this.router.navigate(['']);
          }
        });
    }
    this.error$ = this.store
      .select((s) => selectAuth(s).error)
      .pipe(takeUntilDestroyed());
    this.isStaffApp$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(takeUntilDestroyed());
  }

  async submit() {
    if (this.form.valid) {
      const isStaffApp = await firstValueFrom(this.isStaffApp$);
      if (isStaffApp) {
        this.store.dispatch(authActions.loginStaff(this.model));
      } else {
        this.store.dispatch(authActions.loginCustomer(this.model));
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
