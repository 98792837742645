import { OrderCardAttributeTypeResourceTypeMappings } from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { orderCardAttributeTypeActions } from '../../state/order-card-attribute-type/order-card-attribute-type.actions';

export type OrderCardAttributeTypeFormModel = Partial<
  OrderCardAttributeTypeResourceTypeMappings['createDTO']
>;
export type OrderCardAttributeTypeFormModalData =
  FormModalData<OrderCardAttributeTypeFormModel>;

@Component({
  selector: 'aa-order-card-attribute-type-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class OrderCardAttributeTypeFormModalComponent extends BaseFormModalComponent<
  OrderCardAttributeTypeFormModel,
  OrderCardAttributeTypeFormModalData
> {
  title = 'Order Card Attribute Type';
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'fieldName',
      type: 'input',
      props: {
        label: 'Field Name',
        type: 'text',
        required: true,
      },
    },
  ];

  createAction = orderCardAttributeTypeActions.createItem;
  updateAction = orderCardAttributeTypeActions.updateItem;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: OrderCardAttributeTypeFormModalData,
    protected override readonly dialogRef: MatDialogRef<OrderCardAttributeTypeFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
  }
}
