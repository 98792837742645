import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserRoleAssignment } from '@prisma/client';
import { UserRoleAssignmentResourceTypeMappings } from '@aa/nest/resource';
import { userRoleAssignmentCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class UserRoleAssignmentService extends BaseResourceService<UserRoleAssignmentResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(
      http,
      store,
      'user-role-assignments',
      undefined,
      userRoleAssignmentCompositeKeyOrder,
    );
  }
}
