<div class="container page-section">
  <mat-card>
    <img src="assets/images/large-logo.jpg" />
    @if (submitted) {
      <p id="submitted-message">
        If an account with that email exists, it will receive a reset password
        link shortly.
      </p>
    } @else {
      <h1>Forgot Password</h1>
      <form [formGroup]="form" (submit)="submit()">
        <formly-form [form]="form" [fields]="fields" [model]="model">
        </formly-form>

        <p class="error">{{ error$ | async }}</p>
        <button type="submit" mat-flat-button color="primary">Submit</button>
      </form>

      <p>Know you password? <a href="/login">Login</a></p>
      <p>Don't have an account? <a href="/register">Sign Up</a></p>
    }
  </mat-card>
</div>
