import { OfferedServiceResourceTypeMappings } from '@aa/nest/resource';
import { computeTextColorForBackground, formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { OfferedServiceFormModalComponent } from '../../forms/offered-service-form-modal/offered-service-form-modal.component';
import { offeredServiceActions } from '../../state/offered-service/offered-service.actions';
import { selectOfferedServiceState } from '../../state/offered-service/offered-service.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

// @aa:gen-ignore

@Component({
  selector: 'aa-offered-services-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferedServicesListViewComponent extends BaseResourceListViewComponent<OfferedServiceResourceTypeMappings> {
  title = 'Offered Services';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '3rem',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'stripeProductId',
      label: 'Stripe Product Id',
    },
    {
      key: 'hexColor',
      label: 'Hex Color',
      getBackgroundColor: (row) => row.hexColor ?? '#ffffff',
      getTextColor: (row) =>
        computeTextColorForBackground(row.hexColor ?? '#ffffff'),
    },
    {
      key: 'orderCount',
      label: 'Order Count',
      accessor: (row) => row._count?.orderServiceLinkages,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '15rem',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '15rem',
    },
  ] as DataTableColumnConfig<
    OfferedServiceResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectOfferedServiceState;
  loadAction = offeredServiceActions.loadItems({
    query: {
      include: {
        _count: {
          select: {
            orderServiceLinkages: true,
          },
        },
      },
      pageSize: this.initialPageSize,
    },
  });
  actions = offeredServiceActions;

  createFormModalTypeGetter = () => OfferedServiceFormModalComponent;
  updateFormModalTypeGetter = () => OfferedServiceFormModalComponent;
}
