<aa-view-bar
  [title]="(isEditMode$ | async) ? 'Edit Card' : 'New Card'"
  rightText=""></aa-view-bar>

<div class="customer-view-container v-scrollable">
  <div class="customer-view-sections">
    <div class="section">
      <h2>Images</h2>
      <div class="images">
        <button
          class="add-image-tile"
          [disabled]="aiLoading$ | async"
          [ngStyle]="{
            'background-image': 'url(' + (frontImageDataURL$ | async) + ')'
          }"
          (click)="frontFileUpload.click()">
          <mat-icon>Add</mat-icon>
          <p class="label">Front</p>
          <input
            hidden
            type="file"
            #frontFileUpload
            (change)="handleImageUpload($event)" />
        </button>
        <button
          class="add-image-tile"
          [disabled]="aiLoading$ | async"
          [ngStyle]="{
            'background-image': 'url(' + (backImageDataURL$ | async) + ')'
          }"
          (click)="backFileUpload.click()">
          <mat-icon>Add</mat-icon>
          <p class="label">Back</p>
          <input
            hidden
            type="file"
            #backFileUpload
            (change)="handleImageUpload($event, true)" />
        </button>
      </div>
    </div>

    <div class="section">
      <h2>Attributes</h2>
      @if (aiLoading$ | async) {
        <div class="ai-loading">
          <p>Detecting Attributes</p>
          <mat-spinner></mat-spinner>
        </div>
      }
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model">
        </formly-form>
      </form>
    </div>
  </div>
</div>

<div class="customer-view-actions">
  @if (isEditMode$ | async) {
    <button mat-flat-button color="warn" (click)="promptDelete()">
      Delete
    </button>
  } @else {
    <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
  }
  <button
    mat-flat-button
    color="primary"
    [disabled]="!form.valid"
    (click)="save()">
    Save
  </button>
</div>
