import { OrderResourceTypeMappings } from '@aa/nest/resource';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderService } from '../../services/order.service';
import { BaseResourceEffects } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { orderCardActions } from '../order-card/order-card.actions';
import { orderServiceLinkageActions } from '../order-service-linkage/order-service-linkage.actions';
import { orderActions } from './order.actions';
import { selectOrderState } from './order.reducer';

// @aa:gen-ignore

@Injectable()
export class OrderEffects extends BaseResourceEffects<OrderResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderService,
  ) {
    super(actions$, store, service, orderActions, selectOrderState, [
      orderCardActions.setItems,
      orderServiceLinkageActions.setItems,
    ]);
  }
}
