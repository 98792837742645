import { isValidEmail } from '@aa/ts/common';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { authActions } from '../../state/auth/auth.actions';
import { selectAuth } from '../../state/auth/auth.reducer';
import { CoreAppState } from '../../state/core-app.state';

interface FormModel {
  email: string;
}

@Component({
  selector: 'aa-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
  ],
  templateUrl: './forgot-password-view.component.html',
  styleUrl: './forgot-password-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordViewComponent {
  model: FormModel = { email: '' };
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email address',
        required: true,
      },
      validators: {
        email: {
          expression: (c: AbstractControl) => !c.value || isValidEmail(c.value),
        },
      },
    },
  ];

  submitted = false;
  error$: Observable<string | null | undefined>;

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly authService: AuthService,
  ) {
    this.store.dispatch(authActions.clearError());
    this.error$ = this.store
      .select((s) => selectAuth(s).error)
      .pipe(takeUntilDestroyed());
  }

  async submit() {
    if (this.form.valid) {
      this.submitted = true;
      this.authService.requestResetPassword(this.model.email).subscribe(() => {
        console.log('Requested Password Reset');
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
