import { orderActions } from './order.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderResourceTypeMappings } from '@aa/nest/resource';

export type OrderState = BaseResourceState<OrderResourceTypeMappings>;
export const initialOrderState: OrderState = initialResourceState;

export const orderReducer = createBaseResourceReducer<
  OrderResourceTypeMappings,
  OrderState
>(initialOrderState, orderActions);

export const selectOrderState = (state: CoreAppState) =>
  state.order ?? initialOrderState;
