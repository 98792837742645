import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { firstValueFrom, map, Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { authActions } from '../../state/auth/auth.actions';
import { selectAuth } from '../../state/auth/auth.reducer';
import { CoreAppState } from '../../state/core-app.state';

interface ResetPasswordFormModel {
  newPassword?: string;
  confirmPassword?: string;
}

@Component({
  selector: 'aa-reset-password-view',
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './reset-password-view.component.html',
  styleUrl: './reset-password-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordViewComponent {
  form = new FormGroup({});
  model: ResetPasswordFormModel = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'newPassword',
      type: 'input',
      props: {
        label: 'Password',
        type: 'password',
        required: true,
      },
      validators: ['min-password'],
    },
    {
      key: 'confirmPassword',
      type: 'input',
      props: {
        keydown: (field: FormlyFieldConfig, e: KeyboardEvent) => {
          if (e.key == 'Enter') {
            e.preventDefault();
            this.submit();
          }
        },
        label: 'Confirm New Password',
        type: 'password',
        required: true,
      },
      validators: {
        'confirm-password': () =>
          this.model.newPassword != this.model.confirmPassword,
      },
    },
  ];

  error$: Observable<string | null | undefined>;
  isStaffApp$: Observable<boolean>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly store: Store<CoreAppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.store.dispatch(authActions.clearError());
    if (isPlatformBrowser(this.platformId)) {
      this.store
        .select((s) => selectAuth(s).user)
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          if (user) {
            this.router.navigate(['']);
          }
        });
    }
    this.error$ = this.store
      .select((s) => selectAuth(s).error)
      .pipe(takeUntilDestroyed());
    this.isStaffApp$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(takeUntilDestroyed());
  }

  async submit() {
    const userId = +(
      (await firstValueFrom(
        this.route.paramMap.pipe(map((params) => params.get('userId'))),
      )) ?? 0
    );
    const token =
      (await firstValueFrom(
        this.route.paramMap.pipe(map((params) => params.get('token'))),
      )) ?? undefined;
    if (this.form.valid && userId && token) {
      this.authService
        .resetPassword(userId, this.model.newPassword!, token)
        .subscribe(() => {
          this.router.navigate(['/']);
        });
    }
  }
}
