import {
  AuthEffects,
  CustomerProfileEffects,
  OfferedServiceEffects,
  OrderCardAttributeEffects,
  OrderCardAttributeTypeEffects,
  OrderCardEffects,
  OrderEffects,
  OrderServiceLinkageEffects,
  OrderStatusEffects,
} from '@aa/angular/core';

export const customerAppEffects = [
  AuthEffects,
  OrderEffects,
  OrderCardEffects,
  OfferedServiceEffects,
  OrderServiceLinkageEffects,
  OrderStatusEffects,
  OrderCardAttributeEffects,
  OrderCardAttributeTypeEffects,
  CustomerProfileEffects,
];
