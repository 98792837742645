import { OrderCardAttributeTypeResourceTypeMappings } from '@aa/nest/resource';
import { formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { OrderCardAttributeTypeFormModalComponent } from '../../forms/order-card-attribute-type-form-modal/order-card-attribute-type-form-modal.component';
import { orderCardAttributeTypeActions } from '../../state/order-card-attribute-type/order-card-attribute-type.actions';
import { selectOrderCardAttributeTypeState } from '../../state/order-card-attribute-type/order-card-attribute-type.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

@Component({
  selector: 'aa-order-card-attribute-types-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardAttributeTypesListViewComponent extends BaseResourceListViewComponent<OrderCardAttributeTypeResourceTypeMappings> {
  title = 'Order Card Attribute Types';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '3rem',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'fieldName',
      label: 'Field Name',
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '15rem',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '15rem',
    },
  ] as DataTableColumnConfig<
    OrderCardAttributeTypeResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectOrderCardAttributeTypeState;
  loadAction = orderCardAttributeTypeActions.loadItems({
    query: {
      pageSize: this.initialPageSize,
    },
  });
  actions = orderCardAttributeTypeActions;

  createFormModalTypeGetter = () => OrderCardAttributeTypeFormModalComponent;
  updateFormModalTypeGetter = () => OrderCardAttributeTypeFormModalComponent;
}
