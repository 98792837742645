import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { actionLogActions } from './action-log.actions';
import { ActionLogService } from '../../services/action-log.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectActionLogState } from './action-log.reducer';
import { Store } from '@ngrx/store';
import { ActionLogResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class ActionLogEffects extends BaseResourceEffects<ActionLogResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: ActionLogService,
  ) {
    super(actions$, store, service, actionLogActions, selectActionLogState);
  }
}
