import { UserResourceTypeMappings } from '@aa/nest/auth';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserService } from '../../services/user.service';
import { BaseResourceEffects } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { userActions } from './user.actions';
import { selectUserState } from './user.reducer';

// @aa:gen-ignore

@Injectable()
export class UserEffects extends BaseResourceEffects<UserResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: UserService,
  ) {
    super(actions$, store, service, userActions, selectUserState);
  }
}
