import {
  CoreAppState,
  selectUserRoleState,
  selectUserState,
  userActions,
  userRoleActions,
  userRoleAssignmentActions,
} from '@aa/angular/core';
import {
  UserResourceTypeMappings,
  UserRoleResourceTypeMappings,
} from '@aa/nest/auth';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

export interface ManageUserRolesData {
  userId: number;
}

@Component({
  selector: 'aa-manage-user-roles',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCheckboxModule],
  templateUrl: './manage-user-roles.component.html',
  styleUrl: './manage-user-roles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUserRolesComponent {
  userRoles$: Observable<
    UserRoleResourceTypeMappings['resourceWithRelationsT'][]
  >;
  user$: Observable<UserResourceTypeMappings['resourceWithRelationsT'] | null>;

  selectedRoleIds$ = new BehaviorSubject<number[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: ManageUserRolesData,
    protected readonly dialogRef: MatDialogRef<ManageUserRolesComponent>,
    protected readonly store: Store<CoreAppState>,
  ) {
    this.userRoles$ = this.store
      .select((s) => selectUserRoleState(s).items)
      .pipe(takeUntilDestroyed());
    this.user$ = this.store
      .select((s) => selectUserState(s).current)
      .pipe(takeUntilDestroyed());

    this.store.dispatch(userRoleActions.loadItems({}));
    this.store.dispatch(
      userActions.loadItem({
        id: this.data.userId,
        include: {
          userRoleAssignments: true,
        },
      }),
    );

    this.user$.subscribe((user) => {
      if (user?.userRoleAssignments) {
        this.selectedRoleIds$.next(
          user?.userRoleAssignments?.map((ra) => ra.userRoleId),
        );
      }
    });
  }

  async toggleUserRole(roleId: number) {
    const selected = await firstValueFrom(this.selectedRoleIds$);
    if (selected.includes(roleId)) {
      this.selectedRoleIds$.next([...selected.filter((v) => v != roleId)]);
    } else {
      this.selectedRoleIds$.next([...selected, roleId]);
    }
  }

  async submit() {
    const roleIds = await firstValueFrom(this.selectedRoleIds$);
    console.log(roleIds);

    const preExistingUserRoleIds =
      (await firstValueFrom(this.user$))?.userRoleAssignments?.map(
        (ra) => ra.userRoleId,
      ) ?? [];

    for (const roleId of roleIds) {
      if (!preExistingUserRoleIds.includes(roleId)) {
        this.store.dispatch(
          userRoleAssignmentActions.createItem({
            data: { userId: this.data.userId, userRoleId: roleId },
          }),
        );
      }
    }
    for (const roleId of preExistingUserRoleIds) {
      if (!roleIds.includes(roleId)) {
        this.store.dispatch(
          userRoleAssignmentActions.deleteItem({
            id: {
              userId_userRoleId: {
                userId: this.data.userId,
                userRoleId: roleId,
              },
            },
          }),
        );
      }
    }

    this.dialogRef.close();
  }
}
