import {
  OfferedServiceResourceTypeMappings,
  OrderServiceLinkageResourceTypeMappings,
} from '@aa/nest/resource';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs';
import { OfferedServiceService } from '../../services/offered-service.service';
import { OrderServiceLinkageService } from '../../services/order-service-linkage.service';
import {
  BaseResourceEffects,
  buildLinkageHandlerEffect,
} from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { orderActions } from '../order/order.actions';
import {
  HandleOrderLinkagesDataPayload,
  offeredServiceActions,
} from './offered-service.actions';
import { selectOfferedServiceState } from './offered-service.reducer';

// @aa:gen-ignore

@Injectable()
export class OfferedServiceEffects extends BaseResourceEffects<OfferedServiceResourceTypeMappings> {
  handleOrderLinkages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(offeredServiceActions.handleOrderLinkages),
      filter((payload) => !!payload.data.orderId),
      mergeMap((payload) =>
        buildLinkageHandlerEffect<
          OfferedServiceResourceTypeMappings,
          OrderServiceLinkageResourceTypeMappings,
          OfferedServiceService,
          OrderServiceLinkageService,
          HandleOrderLinkagesDataPayload
        >(
          'offeredServiceId',
          'orderId',
          this.service,
          this.orderServiceLinkageService,
          payload.data,
          [],
        ),
      ),
      map(() => orderActions.refreshItem()),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OfferedServiceService,
    private readonly orderServiceLinkageService: OrderServiceLinkageService,
  ) {
    super(
      actions$,
      store,
      service,
      offeredServiceActions,
      selectOfferedServiceState,
    );
  }
}
