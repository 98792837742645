import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { StaffProfileService } from '../../services/staff-profile.service';
import { UserService } from '../../services/user.service';
import { BaseResourceEffects } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { userRoleAssignmentActions } from '../user-role-assignment/user-role-assignment.actions';
import { userActions } from '../user/user.actions';
import { staffProfileActions } from './staff-profile.actions';
import { selectStaffProfileState } from './staff-profile.reducer';

// @aa:gen-ignore

@Injectable()
export class StaffProfileEffects extends BaseResourceEffects<StaffProfileResourceTypeMappings> {
  createItemWithUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staffProfileActions.createStaffMemberWithUserAccount),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.authService
                .registerStaff(
                  payload.data.email,
                  undefined,
                  payload.data.firstName!,
                  payload.data.lastName!,
                )
                .pipe(
                  map(() =>
                    this.itemActions.loadItems({
                      query: currentQuery as any,
                    }),
                  ),
                  catchError((err) =>
                    of(
                      this.itemActions.setError({
                        error:
                          err.error?.message?.toString() ??
                          err.message?.toString() ??
                          err.toString(),
                      }),
                    ),
                  ),
                ),
            ),
          ),
      ),
    ),
  );

  updateItemWithUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staffProfileActions.updateStaffMemberWithUserAccount),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            tap((r) => console.log(payload)),
            switchMap((currentQuery) =>
              this.service
                .update(payload.id, {
                  ...payload.data,
                  email: undefined,
                  userId: undefined,
                } as any)
                .pipe(
                  switchMap(() =>
                    this.userService
                      .update(payload.data.userId!, {
                        email: payload.data.email,
                      })
                      .pipe(
                        map(() =>
                          this.itemActions.loadItems({
                            query: (currentQuery ?? {}) as any,
                          }),
                        ),
                      ),
                  ),
                  catchError((err) =>
                    of(
                      this.itemActions.setError({
                        error:
                          err.error?.message?.toString() ??
                          err.message?.toString() ??
                          err.toString(),
                      }),
                    ),
                  ),
                ),
            ),
          ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: StaffProfileService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
  ) {
    super(
      actions$,
      store,
      service,
      staffProfileActions,
      selectStaffProfileState,
      [userActions.setItems, userRoleAssignmentActions.setItems],
    );
  }
}
