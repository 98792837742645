<div class="container page-section">
  <mat-card>
    <img src="assets/images/large-logo.jpg" />
    <h1>Reset Password</h1>
    <p>
      New password must be at least 6 characters long and have at least 1
      special character.
    </p>
    <form [formGroup]="form" (submit)="submit()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
      </formly-form>

      <p class="error">{{ error$ | async }}</p>
      <button type="submit" mat-flat-button color="primary">Submit</button>
    </form>
  </mat-card>
</div>
