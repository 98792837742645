<div class="card">
  <h2>User Roles</h2>

  <table class="roles">
    @for (role of userRoles$ | async; track role.id) {
      <tr>
        <td>{{ role.name }}</td>
        <td>{{ role.description }}</td>

        <td>
          <mat-checkbox
            [checked]="(selectedRoleIds$ | async)?.includes(role.id)"
            (click)="toggleUserRole(role.id)"></mat-checkbox>
        </td>
      </tr>
    }
  </table>

  <button mat-flat-button color="primary" (click)="submit()">Save</button>
</div>
