<!-- <input
  matInput
  [ngxMatDatetimePicker]="picker"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [errorStateMatcher]="errorStateMatcher" />

<mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
<ngx-mat-datetime-picker #picker> </ngx-mat-datetime-picker> -->

<mtx-datetimepicker
  #datetimePicker
  [type]="$any(props.type) ?? 'datetime'"
  [twelvehour]="$any(props['twelveHour']) ?? true">
  <mtx-datetimepicker-actions>
    <button mat-button mtxDatetimepickerClear>Clear</button>
    <button mat-button mtxDatetimepickerCancel>Cancel</button>
    <button mat-flat-button color="primary" mtxDatetimepickerApply>
      Apply
    </button>
  </mtx-datetimepicker-actions>
</mtx-datetimepicker>
<input
  [mtxDatetimepicker]="datetimePicker"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [errorStateMatcher]="errorStateMatcher"
  matInput />
<mtx-datetimepicker-toggle
  [for]="datetimePicker"
  matSuffix></mtx-datetimepicker-toggle>
