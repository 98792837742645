import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';
import { isValidEmail } from '@aa/ts/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { staffProfileActions } from '../../state/staff-profile/staff-profile.actions';

export type StaffProfileFormModel = Partial<
  StaffProfileResourceTypeMappings['createDTO']
> & {
  email: string;
  userId?: number;
};
export type StaffProfileFormModalData = FormModalData<StaffProfileFormModel>;

@Component({
  selector: 'aa-staff-profile-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class StaffProfileFormModalComponent extends BaseFormModalComponent<
  StaffProfileFormModel,
  StaffProfileFormModalData
> {
  title = 'Staff Member';
  fields: FormlyFieldConfig[] = [
    {
      key: 'userId',
      type: 'input',
      hide: true,
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'First Name',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Last Name',
            type: 'text',
            required: true,
          },
        },
      ],
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email',
        type: 'text',
        required: true,
      },
      validators: {
        email: {
          expression: (c: AbstractControl) => !c.value || isValidEmail(c.value),
        },
      },
    },
    {
      key: 'notes',
      type: 'input',
      props: {
        label: 'Notes',
        type: 'text',
        required: false,
      },
    },
    {
      template:
        '<p class="form-modal-text"><strong>Note: </strong>After creation, an email will be sent to the new user with a generated password. After signing in for the first time they can reset their password via the settings page.</p>',
      expressions: {
        hide: () => this.data.mode !== 'create',
      },
    },
  ];

  createAction = staffProfileActions.createStaffMemberWithUserAccount;
  updateAction = staffProfileActions.updateStaffMemberWithUserAccount;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: StaffProfileFormModalData,
    protected override readonly dialogRef: MatDialogRef<StaffProfileFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);

    this.dontStripKeys = ['userId'];
  }
}
