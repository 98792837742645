import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderStatus } from '@prisma/client';
import { OrderStatusResourceTypeMappings } from '@aa/nest/resource';
import { orderStatusCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderStatusService extends BaseResourceService<OrderStatusResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'order-statuses');
  }
}
