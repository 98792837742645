import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderServiceLinkage } from '@prisma/client';
import { OrderServiceLinkageResourceTypeMappings } from '@aa/nest/resource';
import { orderServiceLinkageCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderServiceLinkageService extends BaseResourceService<OrderServiceLinkageResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(
      http,
      store,
      'order-service-linkages',
      undefined,
      orderServiceLinkageCompositeKeyOrder,
    );
  }
}
