<div class="container">
  <h1>{{ title }}</h1>
  @if (loading$ | async) {
    <mat-spinner></mat-spinner>
  }
  @if ((loading$ | async) === false) {
    <form [formGroup]="form" autocomplete="off">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"></formly-form>
    </form>
  }
  @if ((loading$ | async) === false) {
    <button
      id="submit-button"
      [disabled]="!form.valid"
      (click)="submit()"
      type="submit"
      color="primary"
      mat-flat-button>
      Submit
    </button>
  }
</div>
