import { countries, isValidEmail, states } from '@aa/ts/common';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { Observable } from 'rxjs';
import { authActions } from '../../state/auth/auth.actions';
import { selectAuth } from '../../state/auth/auth.reducer';
import { CoreAppState } from '../../state/core-app.state';

interface RegisterFormModel {
  firstName: string;
  lastName: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  password: string;
}

@Component({
  selector: 'aa-register-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './register-view.component.html',
  styleUrl: './register-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterViewComponent {
  form = new FormGroup({});
  model: RegisterFormModel = {
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    password: '',
  };
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'First Name',
            required: true,
          },
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Last Name',
            required: true,
          },
        },
      ],
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email address',
        required: true,
      },
      validators: {
        email: {
          expression: (c: AbstractControl) => !c.value || isValidEmail(c.value),
        },
      },
    },
    {
      key: 'addressLine1',
      type: 'input',
      props: {
        label: 'Address Line1',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'addressLine2',
      type: 'input',
      props: {
        label: 'Address Line2',
        type: 'text',
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'city',
          type: 'input',
          props: {
            label: 'City',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'state',
          type: 'select',
          props: {
            label: 'State',
            options: states.map((state) => ({
              label: state.name,
              value: state.code,
            })),
            required: true,
          },
        },
        {
          key: 'zipCode',
          type: 'input',
          props: {
            label: 'Zip Code',
            type: 'text',
            required: true,
          },
        },
      ],
    },
    {
      key: 'country',
      type: 'select',
      defaultValue: 'US',
      props: {
        label: 'Country',
        options: countries.map((country) => ({
          label: country.name,
          value: country.code,
        })),
        required: true,
        disabled: true,
      },
    },
    {
      key: 'password',
      type: 'input',
      props: {
        keydown: (field: FormlyFieldConfig, e: KeyboardEvent) => {
          if (e.key == 'Enter') {
            e.preventDefault();
            this.submit();
          }
        },
        label: 'Password',
        type: 'password',
        required: true,
      },
      validators: ['min-password'],
    },
  ];

  error$: Observable<string | null | undefined>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly store: Store<CoreAppState>,
    private readonly router: Router,
  ) {
    this.store.dispatch(authActions.clearError());
    if (isPlatformBrowser(this.platformId)) {
      this.store
        .select((s) => selectAuth(s).user)
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          if (user) {
            this.router.navigate(['']);
          }
        });
    }
    this.error$ = this.store.select((s) => selectAuth(s).error);
  }

  submit() {
    if (this.form.valid)
      this.store.dispatch(authActions.registerCustomer(this.model));
  }
}
