<div class="container">
  <button mat-flat-button (click)="goBack()">
    <mat-icon>chevron_left</mat-icon>
    Back
  </button>

  @if (largeTitle) {
    <h1 class="title">{{ title }}</h1>
  } @else {
    <h3 class="title">{{ title }}</h3>
  }

  <div class="right">
    @if (rightText) {
      <p>{{ rightText }}</p>
    }
  </div>
</div>
