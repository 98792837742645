import { orderCardAttributeActions } from './order-card-attribute.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderCardAttributeResourceTypeMappings } from '@aa/nest/resource';

export type OrderCardAttributeState =
  BaseResourceState<OrderCardAttributeResourceTypeMappings>;
export const initialOrderCardAttributeState: OrderCardAttributeState =
  initialResourceState;

export const orderCardAttributeReducer = createBaseResourceReducer<
  OrderCardAttributeResourceTypeMappings,
  OrderCardAttributeState
>(initialOrderCardAttributeState, orderCardAttributeActions);

export const selectOrderCardAttributeState = (state: CoreAppState) =>
  state.orderCardAttribute ?? initialOrderCardAttributeState;
