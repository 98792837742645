import { customerProfileActions } from './customer-profile.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { CustomerProfileResourceTypeMappings } from '@aa/nest/resource';

export type CustomerProfileState =
  BaseResourceState<CustomerProfileResourceTypeMappings>;
export const initialCustomerProfileState: CustomerProfileState =
  initialResourceState;

export const customerProfileReducer = createBaseResourceReducer<
  CustomerProfileResourceTypeMappings,
  CustomerProfileState
>(initialCustomerProfileState, customerProfileActions);

export const selectCustomerProfileState = (state: CoreAppState) =>
  state.customerProfile ?? initialCustomerProfileState;
