import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, forkJoin, map, mergeMap, of, switchMap, take } from 'rxjs';
import { OrderCardAttributeTypeService } from '../../services/order-card-attribute-type.service';
import { OrderCardAttributeService } from '../../services/order-card-attribute.service';
import { OrderCardService } from '../../services/order-card.service';
import { BaseResourceEffects, NoAction } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { orderCardActions } from './order-card.actions';
import { selectOrderCardState } from './order-card.reducer';

// @aa:gen-ignore

@Injectable()
export class OrderCardEffects extends BaseResourceEffects<OrderCardResourceTypeMappings> {
  createCardWithAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderCardActions.createCardWithAttributes),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.create(payload.cardData).pipe(
                switchMap((res) => {
                  const observables = [];
                  for (const attribute of payload.attributes) {
                    observables.push(
                      this.orderCardAttributeTypeService
                        .getMany({
                          where: {
                            fieldName: attribute.fieldName,
                          },
                        })
                        .pipe(
                          switchMap((attributeTypesRes) =>
                            attributeTypesRes.data.length > 0
                              ? this.orderCardAttributeService.create({
                                  orderCardId: res.id,
                                  orderCardAttributeTypeId:
                                    attributeTypesRes.data[0].id,
                                  value: `${attribute.value}`,
                                })
                              : of(NoAction()),
                          ),
                          catchError((err) =>
                            of(
                              this.itemActions.setError({
                                error:
                                  err.error?.message?.toString() ??
                                  err.message?.toString() ??
                                  err.toString(),
                              }),
                            ),
                          ),
                        ),
                    );
                  }

                  return forkJoin(observables).pipe(
                    map(() =>
                      this.itemActions.loadItems({
                        query: currentQuery as any,
                      }),
                    ),
                  );
                }),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  updatedCardWithAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderCardActions.updateCardWithAttributes),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.update(payload.id, payload.cardUpdates).pipe(
                switchMap((res) => {
                  const observables = [];
                  for (const attribute of payload.attributes) {
                    if (
                      attribute.orderCardId &&
                      attribute.orderCardAttributeTypeId
                    ) {
                      observables.push(
                        this.orderCardAttributeService.update(
                          {
                            orderCardId: attribute.orderCardId,
                            orderCardAttributeTypeId:
                              attribute.orderCardAttributeTypeId,
                          },
                          {
                            value: attribute.value,
                          },
                        ),
                      );
                    } else {
                      observables.push(
                        this.orderCardAttributeTypeService
                          .getMany({
                            where: {
                              fieldName: attribute.fieldName,
                            },
                          })
                          .pipe(
                            switchMap((attributeTypesRes) =>
                              attributeTypesRes.data.length > 0
                                ? this.orderCardAttributeService.create({
                                    orderCardId: res.id,
                                    orderCardAttributeTypeId:
                                      attributeTypesRes.data[0].id,
                                    value: `${attribute.value}`,
                                  })
                                : of(NoAction()),
                            ),
                            catchError((err) =>
                              of(
                                this.itemActions.setError({
                                  error:
                                    err.error?.message?.toString() ??
                                    err.message?.toString() ??
                                    err.toString(),
                                }),
                              ),
                            ),
                          ),
                      );
                    }
                  }

                  return forkJoin(observables).pipe(
                    map(() =>
                      this.itemActions.loadItems({
                        query: currentQuery as any,
                      }),
                    ),
                  );
                }),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderCardService,
    private readonly orderCardAttributeService: OrderCardAttributeService,
    private readonly orderCardAttributeTypeService: OrderCardAttributeTypeService,
  ) {
    super(actions$, store, service, orderCardActions, selectOrderCardState);
  }
}
