import { OrderStatusResourceTypeMappings } from '@aa/nest/resource';
import { computeTextColorForBackground, formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { OrderStatusFormModalComponent } from '../../forms/order-status-form-modal/order-status-form-modal.component';
import { orderStatusActions } from '../../state/order-status/order-status.actions';
import { selectOrderStatusState } from '../../state/order-status/order-status.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

// @aa:gen-ignore

@Component({
  selector: 'aa-order-statuses-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusesListViewComponent extends BaseResourceListViewComponent<OrderStatusResourceTypeMappings> {
  title = 'Order Statuses';
  columns = [
    {
      key: 'id',
      label: 'Id',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'hexColor',
      label: 'Hex Color',
      getBackgroundColor: (row) => row.hexColor ?? '#ffffff',
      getTextColor: (row) =>
        computeTextColorForBackground(row.hexColor ?? '#ffffff'),
    },
    {
      key: 'orderCount',
      label: 'Order Count',
      accessor: (row) => row._count?.orders,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
    },
  ] as DataTableColumnConfig<
    OrderStatusResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectOrderStatusState;
  loadAction = orderStatusActions.loadItems({
    query: {
      include: {
        _count: {
          select: {
            orders: true,
          },
        },
      },

      pageSize: this.initialPageSize,
    },
  });
  actions = orderStatusActions;

  createFormModalTypeGetter = () => OrderStatusFormModalComponent;
  updateFormModalTypeGetter = () => OrderStatusFormModalComponent;
}
