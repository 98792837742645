export enum ORDER_STATUS {
  DRAFT = 1,
  PENDING_RECEIPT_FROM_CUSTOMER,
  RECEIVED,
  ACCURACY_RESEARCH,
  REMOVE_FROM_SLAB,
  EVALUATION,
  OUT_FOR_GRADING,
  COMPLETED,
}
