<div class="customer-view-container">
  <h1>Welcome {{ customerName$ | async }}</h1>
  <div class="customer-view-sections">
    <div class="section">
      <h2>Drafts</h2>
      @for (draft of drafts$ | async; track draft.id) {
        <aa-order-list-card [order]="draft"></aa-order-list-card>
      }
      @if (((drafts$ | async) ?? []).length < 1) {
        <p>No drafts yet.</p>
      }
    </div>
    <div class="section">
      <h2>Submissions</h2>
      @for (submission of submissions$ | async; track submission.id) {
        <aa-order-list-card [order]="submission"></aa-order-list-card>
      }
      @if (((submissions$ | async) ?? []).length < 1) {
        <p>No submissions yet.</p>
      }
    </div>
  </div>
</div>

<button
  id="create-order-button"
  mat-fab
  color="primary"
  routerLink="draft-editor"
  matTooltip="Create New Draft"
  matTooltipPosition="left"
  #createTooltip="matTooltip">
  <mat-icon>add</mat-icon>
</button>
