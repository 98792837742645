<div class="container">
  <div class="content" [ngClass]="{ 'full-width': isStaffApp$ | async }">
    <div class="brand">
      @if (isStaffApp$ | async) {
        <button mat-icon-button (click)="toggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
      }
      <a href="/"><img src="assets/images/small-logo.jpg" /></a>
    </div>

    <div class="actions">
      <button mat-flat-button [matMenuTriggerFor]="userMenu">
        {{ userName$ | async }}
        <mat-icon iconPositionEnd>account_circle</mat-icon>
      </button>
      <mat-menu #userMenu xPosition="before">
        <button mat-flat-button routerLink="settings">
          Settings <mat-icon iconPositionEnd>settings</mat-icon>
        </button>
        <button mat-flat-button (click)="logout()">
          Logout <mat-icon iconPositionEnd>logout</mat-icon>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
