import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface HelpModalData {
  imagePath: string;
}

@Component({
  selector: 'aa-help-modal',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  templateUrl: './help-modal.component.html',
  styleUrl: './help-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: HelpModalData,
    public dialogRef: MatDialogRef<HelpModalComponent>,
  ) {}
}
