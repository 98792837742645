import { CustomerProfileResourceTypeMappings } from '@aa/nest/resource';
import { formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { environment } from '../../environments/environment';
import { CustomerProfileFormModalComponent } from '../../forms/customer-profile-form-modal/customer-profile-form-modal.component';
import { customerProfileActions } from '../../state/customer-profile/customer-profile.actions';
import { selectCustomerProfileState } from '../../state/customer-profile/customer-profile.reducer';
import { userActions } from '../../state/user/user.actions';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

// @aa:gen-ignore

@Component({
  selector: 'aa-customer-profiles-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerProfilesListViewComponent
  extends BaseResourceListViewComponent<CustomerProfileResourceTypeMappings>
  implements OnInit
{
  title = 'Customer Profiles';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '6rem',
    },
    {
      key: 'firstName',
      label: 'Name',
      accessor: (row) => `${row.firstName} ${row.lastName}`,
      isSortable: true,
      minWidth: '12rem',
    },
    {
      key: 'notes',
      label: 'Notes',
      minWidth: '20rem',
    },
    {
      key: 'stripeCustomerId',
      label: 'Stripe Customer ID',
      isSortable: true,
      getLinkValue: (row) =>
        (environment.production
          ? `https://dashboard.stripe.com/customers/`
          : `https://dashboard.stripe.com/test/customers/`) +
        row.stripeCustomerId,
    },
    {
      key: 'userId',
      label: 'User ID',
      accessor: (row) => row.user?.id,
    },
    {
      key: 'email',
      label: 'Email',
      accessor: (row) => row.user?.email,
    },
    {
      key: 'retryCount',
      label: 'Retry Count',
      accessor: (row) => row.user?.retryCount,
    },
    {
      key: 'lockedOutUntil',
      label: 'Locked Out Until',
      accessor: (row) => formatDateTime(row.user?.lockedOutUntil),
    },
    {
      key: 'disabled',
      label: 'do_not_disturb_on',
      valueType: 'icon',
      labelType: 'icon',
      accessor: (row) => (row.user?.disabled ? 'do_not_disturb_on' : null),
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '12rem',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '12rem',
    },
  ] as DataTableColumnConfig<
    CustomerProfileResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectCustomerProfileState;
  loadAction = customerProfileActions.loadItems({
    query: {
      include: {
        _count: {
          select: {
            orders: true,
          },
        },
        user: true,
      },
      pageSize: this.initialPageSize,
    },
  });
  actions = customerProfileActions;

  override hideAdd = true;

  createFormModalTypeGetter = () => CustomerProfileFormModalComponent;
  updateFormModalTypeGetter = () => CustomerProfileFormModalComponent;

  override async ngOnInit() {
    await super.ngOnInit();

    this.tableConfig = {
      ...this.tableConfig,
      onAdd: undefined,
      rowActions: [
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
          onClick: (item) => this.openEditForm(item),
        },
        {
          key: 'clearLockout',
          label: 'Clear Lockout',
          icon: 'clear',
          onClick: (item) => this.clearLockout(item),
        },
        {
          key: 'toggleEnabled',
          label: 'Toggle Enabled/Disabled',
          icon: 'toggle_on',
          onClick: (item) => this.toggleDisabled(item),
        },
      ],
    };
  }

  clearLockout(
    row: CustomerProfileResourceTypeMappings['resourceWithRelationsT'],
  ) {
    if (row.user) {
      this.store.dispatch(
        userActions.updateItem({
          id: row.user!.id,
          data: {
            retryCount: 0,
            lockedOutUntil: null as any,
          },
        }),
      );
    }
  }

  toggleDisabled(
    row: CustomerProfileResourceTypeMappings['resourceWithRelationsT'],
  ) {
    if (row.user) {
      this.store.dispatch(
        userActions.updateItem({
          id: row.user!.id,
          data: {
            disabled: !row.user?.disabled,
          },
        }),
      );
    }
  }
}
