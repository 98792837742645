import { UserRoleResourceTypeMappings } from '@aa/nest/auth';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';

// @aa:gen-ignore

@Injectable()
export class UserRoleService extends BaseResourceService<UserRoleResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'user-roles');
  }
}
