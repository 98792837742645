import { protos } from '@google-cloud/documentai';
import * as inflection from 'inflection';

export const getTextDetectionResultsFromEntities = (
  entities: protos.google.cloud.documentai.v1.Document.IEntity[],
) => {
  const formattedEntityData: Record<string, string> = {};
  for (const entity of entities ?? []) {
    if (entity.type)
      formattedEntityData[inflection.camelize(entity.type, true)] =
        entity.mentionText ?? '';
  }

  return formattedEntityData;
};
