import { UserResourceTypeMappings } from '@aa/nest/auth';
import { BaseResourceTypeMappings, FindManyDTO } from '@aa/nest/common';
import { computeTextColorForBackground } from '@aa/ts/common';
import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { Action, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { FormModalData } from '../../components/base-form-modal/base-form-modal.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../../components/confirm-dialog/confirm-dialog.component';
import {
  DataTableColumnConfig,
  DataTableComponent,
  DataTableConfig,
  DataTableRowAction,
} from '../../components/data-table/data-table.component';
import { selectAuth } from '../../state/auth/auth.reducer';
import { CoreAppState } from '../../state/core-app.state';
import { BaseResourceActions } from '../../state/create-base-resource-actions';
import { BaseResourceState } from '../../state/create-base-resource-reducer';

export interface BaseListViewFilter<
  FilterOptionT = any,
  QueryT extends FindManyDTO<any, any> = FindManyDTO<any, any>,
> {
  key: string;
  label: string;
  type?: 'select' | 'autocomplete' | 'search' | 'chips';
  options?: {
    label: string;
    value: string | number | boolean | undefined;
    icon?: string;
    backgroundHexColor?: string;
  }[];
  stateSelector?: (state: CoreAppState) => BaseResourceState<any>;
  queryBuilder?: (
    v: FilterOptionT,
    user?: UserResourceTypeMappings['resourceWithRelationsT'],
  ) => QueryT['where'];
  getOptionLabel?: (o: FilterOptionT) => string | undefined;
  getOptionIcon?: (o: FilterOptionT) => string | undefined;
  getOptionBackgroundHexColor?: (o: FilterOptionT) => string | undefined;
  loadAction?: (
    user?: UserResourceTypeMappings['resourceWithRelationsT'],
  ) => Action;
  multiple?: boolean;
  show$?: Observable<boolean>;
}

export interface BaseListViewAction<ListActionT = any> {
  key: string;
  label: string;
  type?: 'button' | 'manage-chips';
  options?: {
    label: string;
    value: string | number | boolean | undefined;
    icon?: string;
    backgroundHexColor?: string;
  }[];
  show$?: Observable<boolean>;
  stateSelector?: (state: CoreAppState) => BaseResourceState<any>;
  getOptionLabel?: (o: ListActionT) => string | undefined;
  getOptionIcon?: (o: ListActionT) => string | undefined;
  getOptionBackgroundHexColor?: (o: ListActionT) => string | undefined;
  loadAction?: (
    user?: UserResourceTypeMappings['resourceWithRelationsT'],
  ) => Action;
  toRemove$?: Observable<any[]>;
  onChange?: (value: any, remove?: boolean) => any;
}

export interface BaseResourceListViewStateConfig<
  ResourceTypeMappings extends
    BaseResourceTypeMappings<any> = BaseResourceTypeMappings<any>,
> {
  selector: (s: CoreAppState) => BaseResourceState<ResourceTypeMappings>;
  loadAction: Action;
}

export const baseResourceListViewImports = [
  CommonModule,
  DataTableComponent,
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatChipsModule,
];

@Component({
  selector: 'aa-base-resource-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl: './base-resource-list-view.component.html',
  styleUrl: './base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseResourceListViewComponent<
    ResourceTypeMappings extends
      BaseResourceTypeMappings<any> = BaseResourceTypeMappings<any>,
    FormModalDataT extends FormModalData<any> = FormModalData<any>,
  >
  implements OnInit, OnChanges
{
  abstract title: string;
  abstract columns: DataTableColumnConfig<
    ResourceTypeMappings['resourceWithRelationsT']
  >[];
  abstract stateSelector: (
    s: CoreAppState,
  ) => BaseResourceState<ResourceTypeMappings>;
  abstract loadAction: Action;
  abstract actions: BaseResourceActions<ResourceTypeMappings>;
  abstract createFormModalTypeGetter: () => ComponentType<unknown> | undefined;
  abstract updateFormModalTypeGetter: () => ComponentType<unknown> | undefined;

  items$: Observable<ResourceTypeMappings['resourceWithRelationsT'][]>;
  tableConfig: DataTableConfig<ResourceTypeMappings['resourceWithRelationsT']> =
    {
      columns: [],
      data$: of([]),
      numItems$: of(0),
    };

  @Input()
  where?: ResourceTypeMappings['whereT'];
  @Input()
  include?: ResourceTypeMappings['includeT'];

  @Input()
  hideColumns: string[] = [];
  @Input()
  hideAdd?: boolean;
  @Input()
  inComponent?: boolean;

  itemCount$: Observable<number | undefined>;
  query$: Observable<FindManyDTO | undefined | null>;
  currentUser$: Observable<
    UserResourceTypeMappings['resourceWithRelationsT'] | null | undefined
  >;

  filters: BaseListViewFilter[] = [];
  listActions: BaseListViewAction[] = [];
  lastTypedInFilter: DateTime | null = null;
  initialPageSize = 10;
  displayedColumns: string[] = [];
  filterValues: Record<string, any> = {};
  selectedRows$ = new BehaviorSubject<
    ResourceTypeMappings['resourceWithRelationsT'][]
  >([]);

  constructor(
    protected readonly destroyRef: DestroyRef,
    protected readonly store: Store<CoreAppState>,
    protected readonly dialog: MatDialog,
  ) {
    this.items$ = this.store
      .select((s) => this.stateSelector(s).items)
      .pipe(takeUntilDestroyed());
    this.itemCount$ = this.store
      .select((s) => this.stateSelector(s).itemCount)
      .pipe(takeUntilDestroyed());
    this.query$ = this.store
      .select((s) => this.stateSelector(s).currentQuery)
      .pipe(takeUntilDestroyed());
    this.currentUser$ = this.store
      .select((s) => selectAuth(s).user)
      .pipe(takeUntilDestroyed());
  }

  async ngOnInit() {
    if (this.where || this.include) {
      this.store.dispatch(
        this.actions.loadItems({
          query: {
            where: this.where ?? {},
            include: this.include ?? {},
            pageSize: this.initialPageSize,
          },
        }),
      );
    } else {
      this.store.dispatch(this.loadAction);
    }

    const currentUser = await firstValueFrom(this.currentUser$);
    for (const filter of this.filters) {
      if (filter.loadAction)
        this.store.dispatch(filter.loadAction(currentUser!));
    }

    for (const action of this.listActions) {
      if (action.loadAction)
        this.store.dispatch(action.loadAction(currentUser!));
    }

    this.displayedColumns = [...this.columns, { key: 'rowActions' }]
      .filter((col) => !this.hideColumns.includes(col.key))
      .map((col) => col.key);

    let rowActions: DataTableRowAction[] = [
      {
        key: 'delete',
        label: 'Delete',
        icon: 'delete',
        onClick: (item) => this.promptDeleteItem(item),
      },
    ];
    if (this.updateFormModalTypeGetter() !== undefined) {
      rowActions = [
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
          onClick: (item) => this.openEditForm(item),
        },
        ...rowActions,
      ];
    }

    this.tableConfig = {
      title: this.title,
      shrinkTitle: this.inComponent,
      columns: this.columns,
      data$: this.items$,
      numItems$: this.store
        .select((s) => this.stateSelector(s).itemCount)
        .pipe(
          map((count) => count ?? 0),
          takeUntilDestroyed(this.destroyRef),
        ),
      onAdd: this.hideAdd ? undefined : () => this.openCreateForm(),
      rowActions,
      displayedColumnKeys$: of(this.displayedColumns),
      hideShadow: this.inComponent,
      onPaginationChange: (page) => this.handlePaginationChange(page),
      onSortChange: (sort) => this.handleSortChange(sort),
    };
  }

  async ngOnChanges() {
    await this.ngOnInit();
  }

  handleFilterKeyUp(filter: BaseListViewFilter, event: Event) {
    this.lastTypedInFilter = DateTime.now();
    setTimeout(() => {
      if (
        this.lastTypedInFilter &&
        -1 * this.lastTypedInFilter.diffNow().as('milliseconds') >= 500
      ) {
        this.handleSearchValue(filter, event);
      }
    }, 500);
  }

  async handleFilterValue(filter: BaseListViewFilter, value: any) {
    if (filter.queryBuilder) {
      this.adjustQuery({
        where: filter.queryBuilder(
          value,
          (await firstValueFrom(this.currentUser$)) ?? undefined,
        ),
      });
    } else {
      if (Array.isArray(value)) {
        if (value.length > 0 && value[0] != undefined)
          this.adjustQuery({
            where: {
              [filter.key]: {
                in: value,
              },
            },
          });
        else
          this.adjustQuery({
            where: {
              [filter.key]: undefined,
            },
          });
      } else {
        this.adjustQuery({
          where: {
            ...this.where,
            [filter.key]: value,
          },
        });
      }
    }

    this.filterValues[filter.key] = value;
  }

  async handleSearchValue(filter: BaseListViewFilter, event: Event) {
    const value = (event.target as HTMLInputElement)?.value;
    if (filter.queryBuilder) {
      this.adjustQuery({
        where: filter.queryBuilder(
          value,
          (await firstValueFrom(this.currentUser$)) ?? undefined,
        ),
      });
    } else {
      this.adjustQuery({
        where: {
          [filter.key]: value !== '' ? { contains: value } : undefined,
        },
      });
    }
    this.filterValues[filter.key] = value;
  }

  async toggleSelected(row: ResourceTypeMappings['resourceWithRelationsT']) {
    const selected = await firstValueFrom(this.selectedRows$);
    if (selected.includes(row)) {
      this.selectedRows$.next([...selected.filter((item) => item != row)]);
    } else {
      this.selectedRows$.next([...selected, row]);
    }

    console.log(selected);
  }

  async handleActionClick(action: BaseListViewAction, value?: any) {
    if (action.type == 'button' && action.onChange) action.onChange(value);
    if (action.type == 'manage-chips' && action.onChange) {
      if (action.toRemove$) {
        action.onChange(
          value,
          (await firstValueFrom(action.toRemove$)).includes(value),
        );
      } else {
        action.onChange(value);
      }
    }

    this.selectedRows$.next([]);
  }

  async handleSortChange(sort: Sort) {
    if (['asc', 'desc'].includes(sort.direction))
      this.adjustQuery({
        orderBy: {
          [sort.active]: sort.direction as 'asc' | 'desc',
        },
      });
    else this.adjustQuery({}, ['orderBy']);
  }

  handlePaginationChange(pagination: PageEvent) {
    this.adjustQuery({
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    });
  }

  async openCreateForm() {
    if (this.createFormModalTypeGetter() == undefined) return;
    const data: FormModalDataT = {
      mode: 'create',
    } as any;
    this.dialog.open(this.createFormModalTypeGetter()!, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async openEditForm(item: ResourceTypeMappings['resourceWithRelationsT']) {
    if (this.updateFormModalTypeGetter() == undefined) return;
    const data: FormModalDataT = {
      mode: 'update',
      model: {
        ...this.adjustModelBeforeEdit(item),
      },
    } as any;
    this.dialog.open(this.updateFormModalTypeGetter()!, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async promptDeleteItem(item: ResourceTypeMappings['resourceWithRelationsT']) {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this item?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (confirmation) {
      // TODO: make this also work with non 'id' primary keys
      this.store.dispatch(this.actions.deleteItem({ id: item['id'] }));
    }
  }

  protected async adjustQuery(
    adjustment: ResourceTypeMappings['queryDTO'],
    clearItems: (keyof FindManyDTO)[] = [],
  ) {
    const currentQuery = { ...(await firstValueFrom(this.query$)) };
    console.log(currentQuery, clearItems);
    if (currentQuery && Object.keys(currentQuery).length > 0)
      for (const key of clearItems) {
        if (currentQuery[key]) delete currentQuery[key];
      }

    const modifiedQuery: FindManyDTO = {
      include: { ...currentQuery?.include, ...adjustment.include },
      where: { ...currentQuery?.where, ...adjustment.where },
      orderBy: adjustment.orderBy
        ? { ...adjustment.orderBy }
        : { ...currentQuery?.orderBy },
      page: adjustment?.page ?? currentQuery?.page ?? undefined,
      // TODO: make initial page size variable
      pageSize: adjustment?.pageSize ?? currentQuery?.pageSize ?? 10,
    };
    this.store.dispatch(
      this.actions.loadItems({
        query: modifiedQuery,
      }),
    );
  }

  adjustModelBeforeEdit: (
    item: ResourceTypeMappings['resourceWithRelationsT'],
  ) => FormModalDataT['model'] = (m) => m;

  computeTextColorForBackground = computeTextColorForBackground;
}
