import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderServiceLinkageActions } from './order-service-linkage.actions';
import { OrderServiceLinkageService } from '../../services/order-service-linkage.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderServiceLinkageState } from './order-service-linkage.reducer';
import { Store } from '@ngrx/store';
import { OrderServiceLinkageResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderServiceLinkageEffects extends BaseResourceEffects<OrderServiceLinkageResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderServiceLinkageService,
  ) {
    super(
      actions$,
      store,
      service,
      orderServiceLinkageActions,
      selectOrderServiceLinkageState,
    );
  }
}
