import { orderCardActions } from './order-card.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderCardResourceTypeMappings } from '@aa/nest/resource';

export type OrderCardState = BaseResourceState<OrderCardResourceTypeMappings>;
export const initialOrderCardState: OrderCardState = initialResourceState;

export const orderCardReducer = createBaseResourceReducer<
  OrderCardResourceTypeMappings,
  OrderCardState
>(initialOrderCardState, orderCardActions);

export const selectOrderCardState = (state: CoreAppState) =>
  state.orderCard ?? initialOrderCardState;
