import { actionLogActions } from './action-log.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { ActionLogResourceTypeMappings } from '@aa/nest/resource';

export type ActionLogState = BaseResourceState<ActionLogResourceTypeMappings>;
export const initialActionLogState: ActionLogState = initialResourceState;

export const actionLogReducer = createBaseResourceReducer<
  ActionLogResourceTypeMappings,
  ActionLogState
>(initialActionLogState, actionLogActions);

export const selectActionLogState = (state: CoreAppState) =>
  state.actionLog ?? initialActionLogState;
