import { offeredServiceActions } from './offered-service.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OfferedServiceResourceTypeMappings } from '@aa/nest/resource';

export type OfferedServiceState =
  BaseResourceState<OfferedServiceResourceTypeMappings>;
export const initialOfferedServiceState: OfferedServiceState =
  initialResourceState;

export const offeredServiceReducer = createBaseResourceReducer<
  OfferedServiceResourceTypeMappings,
  OfferedServiceState
>(initialOfferedServiceState, offeredServiceActions);

export const selectOfferedServiceState = (state: CoreAppState) =>
  state.offeredService ?? initialOfferedServiceState;
