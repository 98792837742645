import { orderActions, selectAuth, selectOrderState } from '@aa/angular/core';
import { ORDER_STATUS } from '@aa/nest/resource/objects';
import { formatDate } from '@aa/ts/common';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { OrderResourceTypeMappings } from 'src/libs/nest/resource/src/lib/order/order.resource-type-mappings';
import { OrderListCard } from '../../components/order-list-card/order-list-card.component';
import { CustomerAppState } from '../../state/index.reducers';

@Component({
  selector: 'aa-home-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    OrderListCard,
  ],
  templateUrl: './home-view.component.html',
  styleUrl: './home-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeViewComponent implements AfterViewInit {
  @ViewChild('createTooltip') createTooltip!: MatTooltip;

  customerName$: Observable<string>;
  drafts$: Observable<OrderResourceTypeMappings['resourceWithRelationsT'][]>;
  submissions$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'][]
  >;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly dialog: MatDialog,
  ) {
    this.customerName$ = this.store
      .select((s) => selectAuth(s).user)
      .pipe(map((user) => user?.customerProfile?.firstName ?? 'Customer'));

    this.drafts$ = this.store
      .select((s) => selectOrderState(s).items)
      .pipe(
        map((orders) =>
          orders.filter((o) => o.orderStatusId == ORDER_STATUS.DRAFT),
        ),
        takeUntilDestroyed(),
      );
    this.submissions$ = this.store
      .select((s) => selectOrderState(s).items)
      .pipe(
        map((orders) =>
          orders.filter((o) => o.orderStatusId != ORDER_STATUS.DRAFT),
        ),
        takeUntilDestroyed(),
      );

    this.store
      .select((s) => selectAuth(s).user)
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.store.dispatch(
          orderActions.loadItems({
            query: {
              where: {
                customerProfileId: user?.customerProfileId!,
              },
              include: {
                orderCards: true,
                orderStatus: true,
                orderServiceLinkages: {
                  include: {
                    offeredService: true,
                  },
                },
              },
            },
          }),
        );
      });
  }

  ngAfterViewInit() {
    this.createTooltip.show();
  }

  formatDate = formatDate;
}
