import {
  ForgotPasswordViewComponent,
  LoginViewComponent,
  RegisterViewComponent,
  ResetPasswordViewComponent,
} from '@aa/angular/core';
import {
  DraftEditorViewComponent,
  HomeViewComponent,
  NewCardViewComponent,
  OrderPaymentViewComponent,
  ReviewCardsViewComponent,
  SettingsViewComponent,
} from '@aa/angular/customer';
import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: 'register',
    component: RegisterViewComponent,
  },
  {
    path: 'login',
    component: LoginViewComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordViewComponent,
  },
  {
    path: 'reset-password/:userId/:token',
    component: ResetPasswordViewComponent,
  },
  {
    path: 'settings',
    component: SettingsViewComponent,
  },
  {
    path: '',
    component: HomeViewComponent,
  },
  {
    path: 'draft-editor',
    component: DraftEditorViewComponent,
  },
  {
    path: 'draft-editor/:draftId',
    component: DraftEditorViewComponent,
  },
  {
    path: 'draft-editor/:draftId/new-card',
    component: NewCardViewComponent,
  },
  {
    path: 'draft-editor/:draftId/cards/:cardId',
    component: NewCardViewComponent,
  },
  {
    path: 'draft-editor/:draftId/review-cards',
    component: ReviewCardsViewComponent,
  },
  {
    path: 'order/:orderId/payment',
    component: OrderPaymentViewComponent,
  },
];
