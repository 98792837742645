<div class="container">
  <div class="filters-and-actions-section">
    <div class="items">
      @for (filter of filters; track $index) {
        @if (!filter.show$ || (filter.show$ | async)) {
          @if (filter.type == 'search') {
            <mat-form-field floatLabel="auto">
              <mat-label>{{ filter.label }}</mat-label>
              <input
                matInput
                type="text"
                [value]="filterValues[filter.key] ?? null"
                (keyup)="handleFilterKeyUp(filter, $event)" />

              @if (filterValues[filter.key]) {
                <button
                  mat-icon-button
                  matSuffix
                  aria-label="Clear"
                  (click)="
                    handleFilterValue(filter, undefined);
                    $event.stopPropagation()
                  ">
                  <mat-icon>close</mat-icon>
                </button>
              }
            </mat-form-field>
          } @else if (filter.type == 'chips') {
            <mat-chip-set>
              @for (option of filter.options; track $index) {
                @if (
                  !filterValues[filter.key] ||
                  filterValues[filter.key] == option.value
                ) {
                  <mat-chip
                    (click)="
                      filterValues[filter.key] === option.value
                        ? handleFilterValue(filter, undefined)
                        : handleFilterValue(filter, option.value)
                    "
                    [ngStyle]="{
                      backgroundColor: option.backgroundHexColor ?? '#ffffff',
                      color: computeTextColorForBackground(
                        option.backgroundHexColor ?? '#ffffff'
                      )
                    }">
                    @if (option.icon) {
                      <mat-icon
                        [ngStyle]="{ color: 'inherit' }"
                        matChipAvatar
                        >{{ option.icon }}</mat-icon
                      >
                    }
                    <span
                      [ngStyle]="{
                        color: computeTextColorForBackground(
                          option.backgroundHexColor ?? '#ffffff'
                        )
                      }"
                      >{{ option.label }}</span
                    >
                    @if (filterValues[filter.key]) {
                      <mat-icon
                        matChipTrailingIcon
                        [ngStyle]="{ color: 'inherit' }"
                        >close</mat-icon
                      >
                    }
                  </mat-chip>
                }
              }
            </mat-chip-set>
          } @else {
            <mat-form-field floatLabel="auto">
              <mat-label>{{ filter.label }}</mat-label>
              <mat-select
                [multiple]="filter.multiple"
                [value]="filterValues[filter.key]"
                (valueChange)="handleFilterValue(filter, $event)">
                @for (option of filter.options; track $index) {
                  <mat-option [value]="option.value">{{
                    option.label
                  }}</mat-option>
                }
              </mat-select>
              @if (filterValues[filter.key]) {
                <button
                  mat-icon-button
                  matSuffix
                  aria-label="Clear"
                  (click)="
                    handleFilterValue(filter, undefined);
                    $event.stopPropagation()
                  ">
                  <mat-icon>close</mat-icon>
                </button>
              }
            </mat-form-field>
          }
        }
      }

      @for (action of listActions; track action.key) {
        @if (!action.show$ || (action.show$ | async)) {
          @if (!action.type || action.type == 'button') {
            <button mat-flat-button color="accent">{{ action.label }}</button>
          } @else if (action.type == 'manage-chips') {
            <mat-chip-set>
              @for (option of action.options; track $index) {
                <mat-chip
                  (click)="handleActionClick(action, option.value)"
                  [ngStyle]="{
                    backgroundColor: option.backgroundHexColor ?? '#ffffff',
                    color: computeTextColorForBackground(
                      option.backgroundHexColor ?? '#ffffff'
                    )
                  }">
                  <span
                    [ngStyle]="{
                      color: computeTextColorForBackground(
                        option.backgroundHexColor ?? '#ffffff'
                      )
                    }"
                    >{{ option.label }}</span
                  >
                  @if (
                    action.toRemove$ &&
                    (action.toRemove$ | async)?.includes(option.value)
                  ) {
                    <mat-icon
                      matChipTrailingIcon
                      [ngStyle]="{ color: 'inherit' }"
                      >remove</mat-icon
                    >
                  } @else {
                    <mat-icon
                      matChipTrailingIcon
                      [ngStyle]="{ color: 'inherit' }"
                      >add</mat-icon
                    >
                  }
                </mat-chip>
              }
            </mat-chip-set>
          }
        }
      }
    </div>
  </div>

  <aa-data-table [tableConfig]="tableConfig"></aa-data-table>
</div>
