import { userRoleAssignmentActions } from './user-role-assignment.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { UserRoleAssignmentResourceTypeMappings } from '@aa/nest/resource';

export type UserRoleAssignmentState =
  BaseResourceState<UserRoleAssignmentResourceTypeMappings>;
export const initialUserRoleAssignmentState: UserRoleAssignmentState =
  initialResourceState;

export const userRoleAssignmentReducer = createBaseResourceReducer<
  UserRoleAssignmentResourceTypeMappings,
  UserRoleAssignmentState
>(initialUserRoleAssignmentState, userRoleAssignmentActions);

export const selectUserRoleAssignmentState = (state: CoreAppState) =>
  state.userRoleAssignment ?? initialUserRoleAssignmentState;
