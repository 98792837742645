import { OfferedServiceResourceTypeMappings } from '@aa/nest/resource';
import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';

// @aa:gen-ignore

const domainSlug = '[OFFERED SERVICE]';
export const offeredServiceActions = {
  ...createBaseResourceActions<OfferedServiceResourceTypeMappings>(domainSlug),

  handleOrderLinkages: createAction(
    `${domainSlug} Handle Order Linkages`,
    props<{ data: HandleOrderLinkagesDataPayload }>(),
  ),
};

export type HandleOrderLinkagesDataPayload = {
  orderId: number;
  linkages: {
    offeredServiceId: number;
  }[];
};
