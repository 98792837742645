import {
  authActions,
  CoreAppState,
  selectAuth,
  toggleSidenavIsOpen,
} from '@aa/angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'aa-app-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './app-bar.component.html',
  styleUrl: './app-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {
  userName$: Observable<string>;
  isStaffApp$: Observable<boolean>;

  constructor(private readonly store: Store<CoreAppState>) {
    this.userName$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(
        switchMap((isStaffApp) =>
          this.store
            .select((s) => selectAuth(s).user)
            .pipe(
              map((user) =>
                isStaffApp
                  ? `${user?.staffProfile?.firstName ?? ''} ${
                      user?.staffProfile?.lastName ?? ''
                    }`
                  : `${user?.customerProfile?.firstName ?? ''} ${
                      user?.customerProfile?.lastName ?? ''
                    }`,
              ),
            ),
        ),
        takeUntilDestroyed(),
      );

    this.isStaffApp$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(takeUntilDestroyed());
  }

  toggleSidenav() {
    this.store.dispatch(toggleSidenavIsOpen());
  }

  logout() {
    this.store.dispatch(authActions.logout());
  }
}
