import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderCardAttribute } from '@prisma/client';
import { OrderCardAttributeResourceTypeMappings } from '@aa/nest/resource';
import { orderCardAttributeCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderCardAttributeService extends BaseResourceService<OrderCardAttributeResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(
      http,
      store,
      'order-card-attributes',
      undefined,
      orderCardAttributeCompositeKeyOrder,
    );
  }
}
