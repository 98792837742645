import { orderCardAttributeTypeActions } from './order-card-attribute-type.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderCardAttributeTypeResourceTypeMappings } from '@aa/nest/resource';

export type OrderCardAttributeTypeState =
  BaseResourceState<OrderCardAttributeTypeResourceTypeMappings>;
export const initialOrderCardAttributeTypeState: OrderCardAttributeTypeState =
  initialResourceState;

export const orderCardAttributeTypeReducer = createBaseResourceReducer<
  OrderCardAttributeTypeResourceTypeMappings,
  OrderCardAttributeTypeState
>(initialOrderCardAttributeTypeState, orderCardAttributeTypeActions);

export const selectOrderCardAttributeTypeState = (state: CoreAppState) =>
  state.orderCardAttributeType ?? initialOrderCardAttributeTypeState;
