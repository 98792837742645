import { AuthService, PaymentService, selectAuth } from '@aa/angular/core';
import { UserResourceTypeMappings } from '@aa/nest/auth';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import {
  CustomerProfileFormModalComponent,
  CustomerProfileFormModalData,
} from 'src/libs/angular/core/src/lib/forms/customer-profile-form-modal/customer-profile-form-modal.component';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import { CustomerAppState } from '../../state/index.reducers';

@Component({
  selector: 'aa-settings-view',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, ViewBarComponent],
  templateUrl: './settings-view.component.html',
  styleUrl: './settings-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsViewComponent {
  user$: Observable<
    UserResourceTypeMappings['resourceWithRelationsT'] | null | undefined
  >;
  submittedPasswordReset$ = new BehaviorSubject(false);

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<CustomerAppState>,
    private readonly paymentService: PaymentService,
    private readonly authService: AuthService,
  ) {
    this.user$ = this.store
      .select((s) => selectAuth(s).user)
      .pipe(takeUntilDestroyed());
  }

  async openEditForm() {
    const user = await firstValueFrom(this.user$);
    const data: CustomerProfileFormModalData = {
      mode: 'update',
      model: {
        userId: user?.id,
        email: user?.email,
        ...user?.customerProfile,
        country: user?.customerProfile?.country || 'US',
      } as any,
      hide: ['stripeCustomerId', 'notes'],
    };
    this.dialog.open(CustomerProfileFormModalComponent, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async sendPasswordResetEmail() {
    const user = await firstValueFrom(this.user$);
    const email = user?.email;
    if (email) {
      this.authService.requestResetPassword(email).subscribe(() => {
        console.log('Requested Password Reset');
      });
      this.submittedPasswordReset$.next(true);
    }
  }

  async navigateToStripeCustomerPortal() {
    location.href = (
      await firstValueFrom(this.paymentService.getCustomerPortalURL())
    ).url;
  }
}
