import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderCardAttributeType } from '@prisma/client';
import { OrderCardAttributeTypeResourceTypeMappings } from '@aa/nest/resource';
import { orderCardAttributeTypeCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderCardAttributeTypeService extends BaseResourceService<OrderCardAttributeTypeResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'order-card-attribute-types');
  }
}
