import {
  ExtractedPokemonCardData,
  ExtractedSportsCardData,
} from '@aa/nest/function';
import {
  DetectAttributesDTO,
  DetectAttributesForOrderCardDTO,
} from '@aa/nest/function/objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { httpOptions } from './base-resource.service';

@Injectable()
export class CardAttributeDetectionService {
  constructor(private readonly http: HttpClient) {}

  detectAttributes(data: DetectAttributesDTO) {
    return this.http.post<ExtractedPokemonCardData | ExtractedSportsCardData>(
      `${environment.functionService}/card-attribute-detection`,
      data,
      {
        ...httpOptions,
      },
    );
  }

  detectAttributesForOrderCard(data: DetectAttributesForOrderCardDTO) {
    return this.http.post<ExtractedPokemonCardData | ExtractedSportsCardData>(
      `${environment.functionService}/card-attribute-detection/for-order-card`,
      data,
      {
        ...httpOptions,
      },
    );
  }
}
