import { ActionLogResourceTypeMappings } from '@aa/nest/resource';
import { formatDateTime, getObjectAsHumanReadableString } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as inflection from 'inflection';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { actionLogActions } from '../../state/action-log/action-log.actions';
import { selectActionLogState } from '../../state/action-log/action-log.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

@Component({
  selector: 'aa-action-logs-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLogsListViewComponent
  extends BaseResourceListViewComponent<ActionLogResourceTypeMappings>
  implements OnInit
{
  title = 'Action Logs';
  columns = [
    {
      key: 'userId',
      label: 'User',
      accessor: (row) =>
        row.user?.staffProfile
          ? `Staff: ${row.user?.staffProfile?.firstName ?? ''} ${
              row.user?.staffProfile?.lastName ?? ''
            }`
          : `Customer: ${row.user?.customerProfile?.firstName ?? ''} ${
              row.user?.customerProfile?.lastName ?? ''
            }`,
    },
    {
      key: 'parentResourceId',
      label: 'Parent Resource',
      accessor: (row) =>
        row.parentResourceType
          ? `${inflection.titleize(
              inflection.underscore(row.parentResourceType ?? ''),
            )}-${row.parentResourceId}`
          : '',
    },
    {
      key: 'resource',
      label: 'Resource',
      accessor: (row) =>
        row.resourceType
          ? `${inflection.titleize(
              inflection.underscore(row.resourceType ?? ''),
            )}-${row.resourceId ?? (row.payload as any['id'])}`
          : '',
    },
    {
      key: 'actionType',
      label: 'Action Type',
      accessor: (row) =>
        row.didCreateResource
          ? 'Created'
          : row.didUpdateResource
            ? 'Updated'
            : 'Deleted',
    },
    {
      key: 'payload',
      label: 'Payload',
      valueType: 'html',
      getStyle: () => ({
        'white-space': 'nowrap',
      }),
      accessor: (row) =>
        getObjectAsHumanReadableString(row.payload ?? {}, '<br>'),
    },
    {
      key: 'createdAt',
      label: 'Timestamp',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '15rem',
    },
  ] as DataTableColumnConfig<
    ActionLogResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectActionLogState;
  loadAction = actionLogActions.loadItems({
    query: {
      include: {
        user: {
          include: {
            staffProfile: true,
            customerProfile: true,
          },
        },
      },
      pageSize: this.initialPageSize,
    },
  });
  actions = actionLogActions;

  override async ngOnInit() {
    await super.ngOnInit();
    this.tableConfig = {
      ...this.tableConfig,
      onAdd: undefined,
    };
  }

  createFormModalTypeGetter = () => undefined;
  updateFormModalTypeGetter = () => undefined;
}
