import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'aa-repeating-section-form-field',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    FormlyMaterialModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './repeating-section-form-field.component.html',
  styleUrl: './repeating-section-form-field.component.scss',
})
export class RepeatingSectionFormFieldComponent extends FieldArrayType {}
