import { UserRoleResourceTypeMappings } from '@aa/nest/auth';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserRoleService } from '../../services/user-role.service';
import { BaseResourceEffects } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { userRoleActions } from './user-role.actions';
import { selectUserRoleState } from './user-role.reducer';

// @aa:gen-ignore

@Injectable()
export class UserRoleEffects extends BaseResourceEffects<UserRoleResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: UserRoleService,
  ) {
    super(actions$, store, service, userRoleActions, selectUserRoleState);
  }
}
