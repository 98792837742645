import { OrderSourceResourceTypeMappings } from '@aa/nest/resource';
import { computeTextColorForBackground, formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { OrderSourceFormModalComponent } from '../../forms/order-source-form-modal/order-source-form-modal.component';
import { orderSourceActions } from '../../state/order-source/order-source.actions';
import { selectOrderSourceState } from '../../state/order-source/order-source.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

// @aa:gen-ignore

@Component({
  selector: 'aa-order-sources-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSourcesListViewComponent extends BaseResourceListViewComponent<OrderSourceResourceTypeMappings> {
  title = 'Order Sources';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '3rem',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'hexColor',
      label: 'Hex Color',
      getBackgroundColor: (row) => row.hexColor ?? '#ffffff',
      getTextColor: (row) =>
        computeTextColorForBackground(row.hexColor ?? '#ffffff'),
    },
    {
      key: 'orderCount',
      label: 'Order Count',
      accessor: (row) => row._count?.orders,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '15rem',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '15rem',
    },
  ] as DataTableColumnConfig<
    OrderSourceResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectOrderSourceState;
  loadAction = orderSourceActions.loadItems({
    query: {
      include: {
        _count: {
          select: {
            orders: true,
          },
        },
      },
      pageSize: this.initialPageSize,
    },
  });
  actions = orderSourceActions;

  createFormModalTypeGetter = () => OrderSourceFormModalComponent;
  updateFormModalTypeGetter = () => OrderSourceFormModalComponent;
}
