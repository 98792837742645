<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [errorStateMatcher]="errorStateMatcher" />
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="
    props['getAutocompleteLabel']
      ? props['getAutocompleteLabel']
      : props['getLabel']
  ">
  @for (item of filtered | async; track $index) {
    <mat-option [value]="item">
      {{ props['getLabel'] ? props['getLabel'](item) : item }}
    </mat-option>
  }
</mat-autocomplete>
