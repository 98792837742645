import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderStatusActions } from './order-status.actions';
import { OrderStatusService } from '../../services/order-status.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderStatusState } from './order-status.reducer';
import { Store } from '@ngrx/store';
import { OrderStatusResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderStatusEffects extends BaseResourceEffects<OrderStatusResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderStatusService,
  ) {
    super(actions$, store, service, orderStatusActions, selectOrderStatusState);
  }
}
