import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  orderActions,
} from '@aa/angular/core';
import {
  ORDER_STATUS,
  OrderResourceTypeMappings,
} from '@aa/nest/resource/objects';
import { formatDate } from '@aa/ts/common';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { CustomerAppState } from '../../state/index.reducers';

@Component({
  selector: 'aa-order-list-card',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
  templateUrl: './order-list-card.component.html',
  styleUrl: './order-list-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListCard {
  @Input({ required: true })
  order!: OrderResourceTypeMappings['resourceWithRelationsT'];

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly dialog: MatDialog,
  ) {}

  isDraft() {
    return this.order.orderStatusId == ORDER_STATUS.DRAFT;
  }

  async promptDelete() {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this item?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (confirmation) {
      this.store.dispatch(orderActions.deleteItem({ id: this.order.id }));
    }
  }

  formatDate = formatDate;
}
