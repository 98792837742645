import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderSourceActions } from './order-source.actions';
import { OrderSourceService } from '../../services/order-source.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderSourceState } from './order-source.reducer';
import { Store } from '@ngrx/store';
import { OrderSourceResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderSourceEffects extends BaseResourceEffects<OrderSourceResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderSourceService,
  ) {
    super(actions$, store, service, orderSourceActions, selectOrderSourceState);
  }
}
