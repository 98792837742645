import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'aa-view-bar',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './view-bar.component.html',
  styleUrl: './view-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewBarComponent {
  @Input() title?: string;
  @Input() rightText?: string;
  @Input() backPath?: string;

  @Input() largeTitle = false;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) {}

  goBack() {
    if (this.backPath) this.router.navigate([this.backPath]);
    else this.location.back();
  }
}
