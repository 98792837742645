import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

import { MatButtonModule } from '@angular/material/button';
import { provideNativeDatetimeAdapter } from '@ng-matero/extensions/core';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';

@Component({
  selector: 'aa-date-time-picker-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule,
    MatInputModule,
    MatButtonModule,
    MtxDatetimepickerModule,
  ],
  providers: [provideNativeDatetimeAdapter()],
  templateUrl: './date-time-picker-form-field.component.html',
  styleUrl: './date-time-picker-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerFormFieldComponent extends FieldType<FieldTypeConfig> {}
