<div class="container">
  <div class="header">
    <h1>Help</h1>
    <button mat-icon-button mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- <div class="image-holder"> -->
  <img [src]="data.imagePath" />
  <!-- </div> -->
  <p>
    For further assistance you contact our support department at
    <a href="mailto:support@allangles.ai">support&#64;allangles.ai</a> or at
    <a href="tel:1234567890">+1&nbsp;(123)&nbsp;345&nbsp;6789</a>
  </p>
</div>
