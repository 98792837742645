import {
  CardAttributeDetectionService,
  ConfirmDialogComponent,
  ConfirmDialogData,
  CoreAppState,
  orderCardActions,
  selectOrderCardState,
  StorageService,
} from '@aa/angular/core';
import { UPLOAD_TYPE } from '@aa/nest/storage/objects';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
} from 'rxjs';

import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';

export type CardFormModel = {
  tradingCardType?: string;
  characterName?: string;
  issuedYear?: string;
  cardNumber?: string;
  pokemonType?: string;
  setName?: string;
  playerName?: string;
  productionCompany?: string;
  serialNumber?: string;
  variation?: string;
  notes?: string;
  value?: number;
};

export const cardFormFields: FormlyFieldConfig[] = [
  {
    key: 'tradingCardType',
    type: 'select',
    props: {
      label: 'Card Type',
      options: [
        'Pokemon',
        'Baseball',
        'Basketball',
        'Boxing',
        'Cricket',
        'Football',
        'Golf',
        'Hockey',
        'Racing',
        'Soccer',
        'Tennis',
        'Wrestling',
      ].map((o) => ({ label: o, value: o.toLowerCase() })),
      required: true,
    },
  },
  // pokemon card fields
  {
    key: 'characterName',
    type: 'input',
    props: {
      label: 'Character Name',
      required: true,
    },
    expressions: {
      hide: (field) => field.form?.get('tradingCardType')?.value !== 'pokemon',
    },
  },
  {
    key: 'pokemonType',
    type: 'select',
    props: {
      label: 'Pokemon Type',
      multiple: true,
      options: [
        'Normal',
        'Fire',
        'Water',
        'Electric',
        'Grass',
        'Ice',
        'Fighting',
        'Poison',
        'Ground',
        'Flying',
        'Psychic',
        'Bug',
        'Rock',
        'Ghost',
        'Dragon',
        'Dark',
        'Steel',
        'Fairy',
      ].map((o) => ({ label: o, value: o.toLowerCase() })),
      required: true,
    },
    expressions: {
      hide: (field) => field.form?.get('tradingCardType')?.value !== 'pokemon',
    },
  },
  {
    key: 'setName',
    type: 'input',
    props: {
      label: 'Set Name',
      required: true,
    },
    expressions: {
      hide: (field) => field.form?.get('tradingCardType')?.value !== 'pokemon',
    },
  },
  {
    fieldGroupClassName: 'flex-row-field-group',
    fieldGroup: [
      {
        key: 'cardNumber',
        type: 'input',
        props: {
          label: 'Card Number',
          required: true,
        },
        expressions: {
          hide: (field) =>
            field.form?.get('tradingCardType')?.value !== 'pokemon',
        },
      },
      {
        key: 'issuedYear',
        type: 'input',
        props: {
          label: 'Issued Year',
          required: true,
        },
      },
    ],
    expressions: {
      hide: (field) => field.form?.get('tradingCardType')?.value !== 'pokemon',
    },
  },
  // sports card fields
  {
    key: 'playerName',
    type: 'input',
    props: {
      label: 'Player Name',
      required: true,
    },
    expressions: {
      hide: (field) =>
        !field.form?.get('tradingCardType')?.value ||
        field.form?.get('tradingCardType')?.value == 'pokemon',
    },
  },
  {
    key: 'productionCompany',
    type: 'input',
    props: {
      label: 'Production Company',
      required: true,
    },
    expressions: {
      hide: (field) =>
        !field.form?.get('tradingCardType')?.value ||
        field.form?.get('tradingCardType')?.value == 'pokemon',
    },
  },
  {
    key: 'issuedYear',
    type: 'input',
    props: {
      label: 'Issued Year',
      required: true,
    },
    expressions: {
      hide: (field) =>
        !field.form?.get('tradingCardType')?.value ||
        field.form?.get('tradingCardType')?.value == 'pokemon',
    },
  },
  {
    key: 'Serial Number',
    type: 'input',
    props: {
      label: 'Serial Number',
    },
    expressions: {
      hide: (field) =>
        !field.form?.get('tradingCardType')?.value ||
        field.form?.get('tradingCardType')?.value == 'pokemon',
    },
  },
  // both

  {
    key: 'variation',
    type: 'input',
    props: {
      label: 'Variation',
      placeholder: 'Is this card special in any way?',
    },
    expressions: {
      hide: (field) => !field.form?.get('tradingCardType')?.value,
    },
  },
  {
    key: 'notes',
    type: 'textarea',
    props: {
      label: 'Notes',
      rows: 2,
      placeholder: 'Include any other important details.',
    },
    expressions: {
      hide: (field) => !field.form?.get('tradingCardType')?.value,
    },
  },
];

export const cardFormArrayFields = ['pokemonType'];

@Component({
  selector: 'aa-new-card-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    ViewBarComponent,
  ],
  templateUrl: './new-card-view.component.html',
  styleUrl: './new-card-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCardViewComponent implements AfterViewInit {
  form = new FormGroup({});
  model: CardFormModel = {
    cardNumber: '',
    characterName: '',
    issuedYear: '',
    notes: '',
    playerName: '',
    pokemonType: '',
    productionCompany: '',
    serialNumber: '',
    setName: '',
    // tradingCardType: '',
    variation: '',
  };
  fields: FormlyFieldConfig[] = cardFormFields;

  cardId$: Observable<number | undefined>;
  card$: Observable<
    OrderCardResourceTypeMappings['resourceWithRelationsT'] | null | undefined
  >;
  draftId$: Observable<number | undefined>;
  isEditMode$: Observable<boolean>;

  frontImageFile$ = new BehaviorSubject<File | undefined>(undefined);
  frontImageStorageSlug$ = new BehaviorSubject<string | undefined>(undefined);
  backImageFile$ = new BehaviorSubject<File | undefined>(undefined);
  backImageStorageSlug$ = new BehaviorSubject<string | undefined>(undefined);
  frontImageDataURL$: Observable<string | undefined>;
  backImageDataURL$: Observable<string | undefined>;

  aiCardType$ = new BehaviorSubject<string | undefined>(undefined);
  aiLoading$ = new BehaviorSubject(false);

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly storageService: StorageService,
    private readonly cardAttributeDetectionService: CardAttributeDetectionService,
    private readonly dialog: MatDialog,
  ) {
    this.card$ = this.store
      .select((s) => selectOrderCardState(s).current)
      .pipe(takeUntilDestroyed());
    this.isEditMode$ = this.card$.pipe(map((card) => !!card));

    this.draftId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('draftId')!)),
      takeUntilDestroyed(),
    );
    this.cardId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('cardId')!)),
      takeUntilDestroyed(),
    );

    // load card
    this.cardId$.pipe(distinctUntilChanged()).subscribe((cardId) => {
      if (cardId) {
        this.store.dispatch(
          orderCardActions.loadItem({
            id: cardId,
            include: {
              orderCardAttributes: {
                include: {
                  orderCardAttributeType: true,
                },
              },
            },
          }),
        );
      } else {
        this.store.dispatch(orderCardActions.clearCurrent());
      }
    });

    // handle image previews
    this.frontImageDataURL$ = new Observable((observer) => {
      this.card$.subscribe((card) => {
        if (card) {
          observer.next(card.frontImageURL);
        }
      });
      this.frontImageFile$.subscribe((file) => {
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (typeof fileReader.result !== 'object') {
              observer.next(fileReader.result);
            }
          };
          fileReader.readAsDataURL(file);
        }
      });
    });
    this.backImageDataURL$ = new Observable((observer) => {
      this.card$.subscribe((card) => {
        if (card) {
          observer.next(card.backImageURL);
        }
      });
      this.backImageFile$.subscribe((file) => {
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (typeof fileReader.result !== 'object')
              observer.next(fileReader.result);
          };
          fileReader.readAsDataURL(file);
        }
      });
    });

    combineLatest([
      this.frontImageStorageSlug$.pipe(distinctUntilChanged()),
      this.backImageStorageSlug$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(async ([frontImageSlug, backImageSlug]) => {
        if (frontImageSlug && backImageSlug) {
          this.aiLoading$.next(true);
          const res = await lastValueFrom(
            this.cardAttributeDetectionService.detectAttributes({
              frontImageStorageSlug: frontImageSlug,
              backImageStorageSlug: backImageSlug,
            }),
          );
          this.form.patchValue({
            tradingCardType:
              this.model.tradingCardType ?? res.tradingCardType[0],
          });
          setTimeout(() => {
            for (const [key, valueMapping] of Object.entries(res).filter(
              ([key, value]) => key !== 'tradingCardType',
            )) {
              const field = this.form.get(key);
              if (field) {
                if (cardFormArrayFields.includes(key)) {
                  field.patchValue(
                    valueMapping[0]?.length > 0
                      ? JSON.parse(valueMapping[0])
                      : [],
                  );
                } else {
                  field.patchValue(valueMapping[0]);
                }
              }
            }
            this.aiLoading$.next(false);
          }, 50);
        }
      });

    this.aiLoading$.subscribe((loading) => {
      if (loading) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });
  }

  ngAfterViewInit(): void {
    this.card$?.subscribe((card) => {
      const tradingCardType = card?.orderCardAttributes?.find(
        (ca) => ca.orderCardAttributeType?.fieldName == 'tradingCardType',
      )?.value;

      this.form.patchValue({
        tradingCardType,
      });
      setTimeout(() => {
        this.form.patchValue({
          ...card?.orderCardAttributes?.reduce(
            (obj, attribute) => ({
              ...obj,
              [attribute.orderCardAttributeType?.fieldName]:
                cardFormArrayFields.includes(
                  attribute.orderCardAttributeType?.fieldName,
                )
                  ? JSON.parse(attribute.value)
                  : attribute.value,
            }),
            {} as CardFormModel,
          ),
        });
      }, 50);
    });
  }

  async handleImageUpload(event: Event, isBack = false) {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const uploadedFile = await lastValueFrom(
        this.storageService.uploadFile(
          file,
          isBack ? UPLOAD_TYPE.CARD_BACK : UPLOAD_TYPE.CARD_FRONT,
        ),
      );

      if (isBack) {
        this.backImageFile$.next(file);
        this.backImageStorageSlug$.next(uploadedFile.path);
      } else {
        this.frontImageFile$.next(file);
        this.frontImageStorageSlug$.next(uploadedFile.path);
      }
    }
  }

  cancel() {
    this.location.back();
  }

  async promptDelete() {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this card?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );
    const cardId = await firstValueFrom(this.cardId$);
    if (confirmation && cardId) {
      this.store.dispatch(orderCardActions.deleteItem({ id: cardId }));
      this.location.back();
    }
  }

  async save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const orderId = await firstValueFrom(this.draftId$);
    const cardId = await firstValueFrom(this.cardId$);
    const currentOrderCardAttributes = await firstValueFrom(
      this.card$.pipe(map((card) => card?.orderCardAttributes)),
    );
    const frontImageStorageSlug = await firstValueFrom(
      this.frontImageStorageSlug$,
    );
    const backImageStorageSlug = await firstValueFrom(
      this.backImageStorageSlug$,
    );
    const isEdit = await firstValueFrom(this.isEditMode$);

    console.log(orderId, frontImageStorageSlug, backImageStorageSlug);
    if (isEdit && cardId) {
      this.store.dispatch(
        orderCardActions.updateCardWithAttributes({
          id: cardId,
          cardUpdates: {
            frontImageStorageSlug: frontImageStorageSlug,
            backImageStorageSlug: backImageStorageSlug,
          },
          attributes: Object.entries(this.model).map(([key, value]) => ({
            orderCardId: cardId,
            orderCardAttributeTypeId: currentOrderCardAttributes?.find(
              (ca) => ca.orderCardAttributeType.fieldName == key,
            )?.orderCardAttributeTypeId,
            fieldName: key,
            value: cardFormArrayFields.includes(key)
              ? JSON.stringify(value)
              : value,
          })),
        }),
      );
      this.location.back();
    } else if (orderId && frontImageStorageSlug && backImageStorageSlug) {
      this.store.dispatch(
        orderCardActions.createCardWithAttributes({
          cardData: {
            orderId: orderId,
            frontImageStorageSlug: frontImageStorageSlug,
            backImageStorageSlug: backImageStorageSlug,
          },
          attributes: Object.entries(this.model).map(([key, value]) => ({
            fieldName: key,
            value: cardFormArrayFields.includes(key)
              ? JSON.stringify(value)
              : value,
          })),
        }),
      );

      this.location.back();
    }
  }
}
