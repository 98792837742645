import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { userRoleAssignmentActions } from './user-role-assignment.actions';
import { UserRoleAssignmentService } from '../../services/user-role-assignment.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectUserRoleAssignmentState } from './user-role-assignment.reducer';
import { Store } from '@ngrx/store';
import { UserRoleAssignmentResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class UserRoleAssignmentEffects extends BaseResourceEffects<UserRoleAssignmentResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: UserRoleAssignmentService,
  ) {
    super(
      actions$,
      store,
      service,
      userRoleAssignmentActions,
      selectUserRoleAssignmentState,
    );
  }
}
