import { CustomerProfileResourceTypeMappings } from '@aa/nest/resource';
import { createAction, props } from '@ngrx/store';
import { CustomerProfileFormModel } from '../../forms/customer-profile-form-modal/customer-profile-form-modal.component';
import { createBaseResourceActions } from '../create-base-resource-actions';

// @aa:gen-ignore

const domainSlug = '[CUSTOMER PROFILE]';
export const customerProfileActions = {
  ...createBaseResourceActions<CustomerProfileResourceTypeMappings>(domainSlug),

  updateCustomerProfileWithUserAccount: createAction(
    `${domainSlug} Update Customer Profile with User Account`,
    props<{
      id: CustomerProfileResourceTypeMappings['resourcePrimaryKeyT'];
      data: CustomerProfileFormModel;
      andRefresh?: boolean;
    }>(),
  ),
};
