import { orderStatusActions } from './order-status.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderStatusResourceTypeMappings } from '@aa/nest/resource';

export type OrderStatusState =
  BaseResourceState<OrderStatusResourceTypeMappings>;
export const initialOrderStatusState: OrderStatusState = initialResourceState;

export const orderStatusReducer = createBaseResourceReducer<
  OrderStatusResourceTypeMappings,
  OrderStatusState
>(initialOrderStatusState, orderStatusActions);

export const selectOrderStatusState = (state: CoreAppState) =>
  state.orderStatus ?? initialOrderStatusState;
