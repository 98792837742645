<aa-view-bar backPath="/" title="Order Payment"></aa-view-bar>
<div class="customer-view-container v-scrollable">
  @if (isSuccess$ | async) {
    <h2>Order Payment Processed Successfully</h2>
    <p>Your payment has been processed.</p>
  } @else {
    <h2>Order Payment Failed</h2>
    <p>Failed to Process Payment</p>
  }
</div>
