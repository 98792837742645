<div class="container page-section">
  <mat-card>
    <img src="assets/images/large-logo.jpg" />
    <h1>Login</h1>
    <form [formGroup]="form" (submit)="submit()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
      </formly-form>

      <p class="error">{{ error$ | async }}</p>
      <button type="submit" mat-flat-button color="primary">Login</button>
    </form>

    <p>Forgot your password? <a href="/forgot-password">Reset</a></p>
    <p>Don't have an account? <a href="/register">Sign Up</a></p>
  </mat-card>
</div>
