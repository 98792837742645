import { staffProfileActions } from './staff-profile.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';

export type StaffProfileState =
  BaseResourceState<StaffProfileResourceTypeMappings>;
export const initialStaffProfileState: StaffProfileState = initialResourceState;

export const staffProfileReducer = createBaseResourceReducer<
  StaffProfileResourceTypeMappings,
  StaffProfileState
>(initialStaffProfileState, staffProfileActions);

export const selectStaffProfileState = (state: CoreAppState) =>
  state.staffProfile ?? initialStaffProfileState;
