import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'aa-autocomplete-form-field',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatAutocompleteModule,
    MatOptionModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './autocomplete-form-field.component.html',
})
export class AutocompleteFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  filtered!: Observable<any>;

  ngOnInit() {
    if ((this.props.options as any)?.length) {
      this.filtered = this.formControl.valueChanges.pipe(
        startWith(''),
        switchMap((term) => this.props['filter'](term ?? '')),
      );
    } else if ((this.props.options as any)?.subscribe) {
      this.filtered = this.formControl.valueChanges.pipe(
        startWith(''),
        switchMap(
          (term) =>
            this.props.options as Observable<{ label: string; value: any }[]>,
        ),
      );
    }
  }

  displayFunction(value: any) {
    return typeof value == 'string' ? value : value.label;
  }
}
