import { orderSourceActions } from './order-source.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderSourceResourceTypeMappings } from '@aa/nest/resource';

export type OrderSourceState =
  BaseResourceState<OrderSourceResourceTypeMappings>;
export const initialOrderSourceState: OrderSourceState = initialResourceState;

export const orderSourceReducer = createBaseResourceReducer<
  OrderSourceResourceTypeMappings,
  OrderSourceState
>(initialOrderSourceState, orderSourceActions);

export const selectOrderSourceState = (state: CoreAppState) =>
  state.orderSource ?? initialOrderSourceState;
