import { UserResourceTypeMappings } from '@aa/nest/auth';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService, httpOptions } from './base-resource.service';

// @aa:gen-ignore

@Injectable()
export class UserService extends BaseResourceService<UserResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'users');
  }

  createUserForStaffMember(data: UserResourceTypeMappings['createDTO']) {
    return this.http.post<UserResourceTypeMappings['resourceT']>(
      `${this.endpoint}/${this.resourceSlug}`,
      data,
      {
        ...httpOptions,
      },
    );
  }
}
