import { orderActions, PaymentService, selectAuth } from '@aa/angular/core';
import { ORDER_STATUS } from '@aa/nest/resource/objects';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, map, Observable } from 'rxjs';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import { CustomerAppState } from '../../state/index.reducers';

@Component({
  selector: 'aa-order-payment-view',
  standalone: true,
  imports: [CommonModule, ViewBarComponent],
  templateUrl: './order-payment-view.component.html',
  styleUrl: './order-payment-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentViewComponent {
  isSuccess$: Observable<boolean>;
  sessionId$: Observable<string>;
  orderId$: Observable<number | undefined>;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly route: ActivatedRoute,
    private readonly paymentService: PaymentService,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );

    this.isSuccess$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['success'] == 'true'),
      takeUntilDestroyed(),
    );
    this.sessionId$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['session_id']),
      takeUntilDestroyed(),
    );

    this.isSuccess$.subscribe(async (success) => {
      if (success) {
        const orderId = await firstValueFrom(
          this.orderId$.pipe(filter((orderId) => !!orderId)),
        );
        this.store.dispatch(
          orderActions.updateItem({
            id: orderId!,
            data: {
              orderStatusId: ORDER_STATUS.PENDING_RECEIPT_FROM_CUSTOMER,
            },
          }),
        );
      } else {
      }
    });
    this.sessionId$.subscribe(async (sessionId) => {
      const userId = await firstValueFrom(
        this.store.select((s) => selectAuth(s).user?.id),
      );
      if (userId && sessionId) {
        await firstValueFrom(
          this.paymentService.associateCustomerFromSession(userId, sessionId),
        );
      }
    });
  }
}
