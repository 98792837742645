import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderCardAttributeTypeActions } from './order-card-attribute-type.actions';
import { OrderCardAttributeTypeService } from '../../services/order-card-attribute-type.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderCardAttributeTypeState } from './order-card-attribute-type.reducer';
import { Store } from '@ngrx/store';
import { OrderCardAttributeTypeResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderCardAttributeTypeEffects extends BaseResourceEffects<OrderCardAttributeTypeResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderCardAttributeTypeService,
  ) {
    super(
      actions$,
      store,
      service,
      orderCardAttributeTypeActions,
      selectOrderCardAttributeTypeState,
    );
  }
}
