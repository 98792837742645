import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';
import { formatDateTime } from '@aa/ts/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DataTableColumnConfig } from '../../components/data-table/data-table.component';
import { ManageUserRolesComponent } from '../../components/manage-user-roles/manage-user-roles.component';
import { StaffProfileFormModalComponent } from '../../forms/staff-profile-form-modal/staff-profile-form-modal.component';
import { staffProfileActions } from '../../state/staff-profile/staff-profile.actions';
import { selectStaffProfileState } from '../../state/staff-profile/staff-profile.reducer';
import { userActions } from '../../state/user/user.actions';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';

// @aa:gen-ignore

@Component({
  selector: 'aa-staff-profiles-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffProfilesListViewComponent
  extends BaseResourceListViewComponent<StaffProfileResourceTypeMappings>
  implements OnInit
{
  title = 'Staff Profiles';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '6rem',
      isSortable: true,
    },
    {
      key: 'firstName',
      label: 'First Name',
      minWidth: '12rem',
      isSortable: true,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      minWidth: '12rem',
      isSortable: true,
    },
    {
      key: 'notes',
      label: 'Note',
      minWidth: '20rem',
    },
    {
      key: 'userId',
      label: 'User ID',
      accessor: (row) => row.user?.id,
    },
    {
      key: 'email',
      label: 'Email',
      accessor: (row) => row.user?.email,
    },
    {
      key: 'roles',
      label: 'Roles',
      accessor: (row) =>
        row.user?.userRoleAssignments.map((ra) => ra.userRole?.name).join(', '),
    },
    {
      key: 'retryCount',
      label: 'Retry Count',
      accessor: (row) => row.user?.retryCount,
    },
    {
      key: 'lockedOutUntil',
      label: 'Locked Out Until',
      accessor: (row) => formatDateTime(row.user?.lockedOutUntil),
    },
    {
      key: 'disabled',
      label: 'do_not_disturb_on',
      valueType: 'icon',
      labelType: 'icon',
      accessor: (row) => (row.user?.disabled ? 'do_not_disturb_on' : null),
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '12rem',
      isSortable: true,
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '12rem',
      isSortable: true,
    },
  ] as DataTableColumnConfig<
    StaffProfileResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectStaffProfileState;
  loadAction = staffProfileActions.loadItems({
    query: {
      include: {
        user: {
          include: {
            userRoleAssignments: {
              include: {
                userRole: true,
              },
            },
          },
        },
      },
    },
  });
  actions = staffProfileActions;

  createFormModalTypeGetter = () => StaffProfileFormModalComponent;
  updateFormModalTypeGetter = () => StaffProfileFormModalComponent;

  override async ngOnInit() {
    await super.ngOnInit();

    this.tableConfig = {
      ...this.tableConfig,
      rowActions: [
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
          onClick: (item) => this.openEditForm(item),
        },
        {
          key: 'manageUserRoles',
          label: 'Manage User Roles',
          icon: 'shield_person',
          onClick: (item) => this.openManageUserRoles(item),
        },
        {
          key: 'clearLockout',
          label: 'Clear Lockout',
          icon: 'clear',
          onClick: (item) => this.clearLockout(item),
        },
        {
          key: 'toggleEnabled',
          label: 'Toggle Enabled/Disabled',
          icon: 'toggle_on',
          onClick: (item) => this.toggleDisabled(item),
        },
        {
          key: 'archive',
          label: 'Archive',
          icon: 'delete',
          onClick: (item) => this.promptDeleteItem(item),
        },
      ],
    };
  }

  openManageUserRoles(
    row: StaffProfileResourceTypeMappings['resourceWithRelationsT'],
  ) {
    this.dialog.open(ManageUserRolesComponent, {
      data: {
        userId: row.user?.id,
      },
      minWidth: '30rem',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  clearLockout(
    row: StaffProfileResourceTypeMappings['resourceWithRelationsT'],
  ) {
    if (row.user) {
      this.store.dispatch(
        userActions.updateItem({
          id: row.user!.id,
          data: {
            retryCount: 0,
            lockedOutUntil: null as any,
          },
        }),
      );
    }
  }

  toggleDisabled(
    row: StaffProfileResourceTypeMappings['resourceWithRelationsT'],
  ) {
    if (row.user) {
      this.store.dispatch(
        userActions.updateItem({
          id: row.user!.id,
          data: {
            disabled: !row.user?.disabled,
          },
        }),
      );
    }
  }

  override adjustModelBeforeEdit = (
    item: StaffProfileResourceTypeMappings['resourceWithRelationsT'],
  ) => ({ ...item, userId: item.user?.id, email: item.user?.email });
}
